<template>
  <b-modal
    centered
    v-model="isOpen"
    title="Cancel Order"
    id="cancelOrderModal"
    ref="cancelOrderModal"
    modal-class="cancel-modal"
  >
    <template v-slot:modal-header="{ close }">
      <span class="fs18 lh28 fw500">
        Cancel {{selectedCancelOrders.length}} Selected Order{{selectedCancelOrders.length > 1 ? 's' :''}}
      </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant fw400"></i>
      </b-button>
    </template>
    <p class="pt-20 pt-md-20 text-gray-800">
      Are you sure you want to cancel {{selectedCancelOrders.length > 1 ? 'these' :'this'}} Order{{selectedCancelOrders.length > 1 ? 's' :''}}?
    </p>
    <template v-slot:modal-footer="{ close }">
      <b-button
        variant="secondary-modal w-sm-100 mb-12 mb-md-0"
        @click="cancelFromTable"
        :disabled="isBusy"
        class="m-0 mr-8"
      >
        Yes, Cancel Order{{selectedCancelOrders.length > 1 ? 's' :''}} <b-spinner v-if="isBusy" class="sm"></b-spinner>
      </b-button>
      <b-button
        id="btnHide"
        v-if="!isBusy"
        variant="primary w-sm-100"
        @click="close()"
        class="m-0"
      >
        No, lead me back
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "BulkCancel",
  data() {
    return {
      isOpen: this.modalOpen,
      isBusy: false
    };
  },
  props: [ "modalOpen", "selectedCancelOrders" ],

  methods: {
    async cancelFromTable() {
      this.isBusy = true
      let result = await this.axios.post('/bulcancel/Order_Shipment', {
          orders: this.selectedCancelOrders
      })

      if (result.data.status == true) {
        this.popToast(
          "booked",
          "success",
          "Orders Cancelled Successfully"
        );

      }
      else{
        this.popToast(
          "failed",
          "Error!",
          result.data.error
        );
      }
      this.isBusy = false
      this.$parent.clearSelected();
      setTimeout(() => { 
          this.$parent.orderPageData();
        }, 1000)
      this.isOpen = false;
    },
    clearSelection: function(data) {
      if (this.$parent.clearSelected) {
        this.$parent.clearSelected();
      }
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

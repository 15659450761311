<template>
	<div class="filter-sidebar">
		<div class="divider mx-20 vam"></div>
		<b-button v-b-toggle.sidebarFilters class="text-gray-900" id="side-btn">
			<span >
				Filters
			</span>
			<i
			class="icon-filter text-gray-600 pl-10"
			></i>
		</b-button>
		<b-sidebar
		id="sidebarFilters"
		right
		backdrop
		backdrop-variant="dark"
		sidebar-class="filter-side"
		no-header
		shadow
		v-model="sidebarVisible"
		>
		<b-form class="d-flex flex-column h100vh px-36 pb-32 pt-36">
			<div class="header d-flex align-items-center pb-40">
				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
				<h2 class="text-gray-900">Filters</h2>
				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
					<i class="icon-close-variant"></i>
				</b-button>
			</div>

			<multiselect
			id="type"
			v-model="form.multiKey"
			title="Search by OrderIDs,AWBs or ShipmentIDs"
			:options="['OrderID', 'AWB', 'ShipmentID']"
			:searchable="false"
			:show-labels="false"
			placeholder="Search By"
			class="mb-17"
			@input="addFilter({multiKey: form.multiKey})"
			></multiselect>

			<div v-if="form.multiKey">
				<b-form-textarea
				rows="1"
				:title="'Search by '+form.multiKey+'s'"
				max-rows="3"
				class="mb-17 fs14"
				id="order-id"
				required
				:placeholder="form.multiKey+'s separated by comma'"
				v-model="form.searchValue"
				@input='addFilter({searchValue: form.searchValue.replaceAll("\n",",")})'>
			</b-form-textarea>
		</div>

		<b-input
		class="mb-17"
		title="Manifest ID"
		id="manifest"
		placeholder="Manifest ID"
		v-model="form.manifestID"
		@input="addFilter({manifestID: form.manifestID})"
		></b-input>

		<multiselect
		title="Order Type"
		id="type"
		v-model="form.orderType"
		:options="types"
		:searchable="false"
		:show-labels="false"
		placeholder="Select Order Type"
		class="mb-17"
		@input="addFilter({orderType: form.orderType})"
		></multiselect>

		<multiselect
		id="mode"
		title="Mode Type"
		v-model="form.modeType"
		:options="$store.getters.mode.domestic"
		label="name"
		:searchable="false"
		:show-labels="false"
		placeholder="Select Mode"
		class="mb-17"
		@input="addFilter({modeType: form.modeType ? form.modeType.name : ''})"
		></multiselect>

		<multiselect
		title="Carrier"
		id="carrier"
		v-model="form.carrier"
		:options="$store.getters.carriers"
		label="name"
		:searchable="true"
		:show-labels="false"
		placeholder="Select Carrier"
		class="mb-17"
		@input="addFilter({carrier: form.carrier ? form.carrier.name : ''})"
		></multiselect>

		<multiselect
		id="channel"
		title="Channel"
		v-model="form.channel"
		:options="channels"
		:searchable="true"
		:show-labels="false"
		placeholder="Select Channel"
		label="name"
		class="mb-17"
		@input="addFilter({channel: form.channel ? form.channel.name : ''})"
		></multiselect>

		<multiselect
		id="warehouse"
		title="Warehouse"
		v-model="form.warehouseID"
		:options="$store.getters.warehouses"
		:searchable="true"
		:show-labels="false"
		placeholder="Warehouse"
		class="mb-17"
		label="name"
		@input="addFilter({warehouseID: form.warehouseID ? form.warehouseID.name : ''})"
		></multiselect>

		<date-picker
		prefix-class="xmx"
		title="Date"
		v-model="form.date"
		id="dp date"
		range
		value-type="format"
		format="YYYY-MM-DD"
		placeholder="Start Date - End Date"
		:editable="false"
		:disabled-date="(date) => date > new Date()"
		@input="addFilter({date: form.date})"
		></date-picker>

		<span class="fs11 mt-5 text-red" v-if="$parent.daysFromDates()>30">Data can be exported for maximum 30 days.</span>


		<div class="d-flex mt-20" style="color: #828b9d;font-size: 0.875rem">
			<b-form-checkbox 
			v-model="form.sortBy"
			@input="addFilter({sortBy: form.sortBy == true ? 'true' : 'false'})"
			></b-form-checkbox>
			<label>Sort By orderID</label>
		</div>


		<div class="footer mt-auto text-right pb-32 pt-8">
			<b-link
			v-if="this.hasFilters"
			class="secondary fw500 mr-20"
			@click="clearFilters()"
			>
			Clear filters
		</b-link>
		<b-button
		:disabled="!this.hasFilters"
		variant="primary"
		@click="applyFilter"
		>
		Apply filter
	</b-button>
</div>
</b-form>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
	name: "FilterSidebar",
	props: ['noText'],
	data() {
		return {
			sidebarVisible: false,
			sortBy: null,
			selectedFilter: 1,
			typeSelected: null,
			warehouseSelected: null,
			modetypeSelected: null,
			channelSelected: null,
			orderID: null,
			orderType: null,
			modeType: null,
			carrier: null,
			awb: null,
			modes: null,
			form: {},
			types: ["PREPAID", "COD", "REVERSE"],
			modetypes:[   "Air",
			"Surface-10kg",
			"Lite-2kg",
			"Lite-1kg",
			"Lite-0.5kg",
			"Surface-5kg",
			"Express",
			"Economy",
			"EconomyPlus",
			],
			channels: [],
			selectedFilters: this.$store.getters.filters.shipments || {},
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	mounted() {
		this.getChannels();
	},
	created() {
		let form = Object.assign({}, this.$store.getters.filters.shipments)
		form.date = [this.selectedFilters.dateStart, this.selectedFilters.dateEnd]
		delete form.dateStart
		delete form.dateEnd

		if(form.warehouseID) {
			form.warehouseID = this.$store.getters.warehouses.find(i => i.name == form.warehouseID)
		}
		if(form.channel) {
			form.channel = this.$store.getters.channels.find(i => i.name == form.channel)
		}
		if(form.modeType) {
			form.modeType = this.$store.getters.modes.find(i => i.name == form.modeType)
		}
		if(form.carrier) {
			form.carrier = this.$store.getters.carriers.find(i => i.name == form.carrier)
		}
		this.form = form
	},
	methods: {
		applyFilter(event) {

			if(this.selectedFilters.date) {
				this.selectedFilters.dateStart = this.selectedFilters.date[0]
				this.selectedFilters.dateEnd = this.selectedFilters.date[1]
				delete this.selectedFilters.date
			}

			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'shipments': this.selectedFilters})
			this.$parent.getFilters()

		},

		addFilter(filter) {

			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				let temp = this.selectedFilters
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp)
			}

			if(this.selectedFilters.date) {

				const oneMonth = 86400 * 30 * 1000
				this.isEnabled = oneMonth >= ((+ new Date(this.selectedFilters.date[1])) - (+ new Date(this.selectedFilters.date[0])))

				this.$emit("shipmentExport", this.isEnabled);
			}
		},
		clearFilters(parent) {
			let filter = this.form
			this.form = {}
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(!parent) {
				this.$parent.clearFilters();
			}
		},
		async getChannels() {
			const res = await this.axios.get('/statics/channels')

			this.channels = res.data.filter(i => i.name != '');
		}
    // exportButton() {

    //   if (Object.keys(filters).length && data.orders.length) {
    //     // exportable data
    //     const oneMonth = 86400 * 30 * 1000
    //     let isEnabled = oneMonth >= ((+ new Date(filters.dateEnd)) - (+ new Date(filters.dateStart)))

    //     markup += `<div class="export-data">
    //         <button id="export-orders" ${isEnabled ? "" : "disabled"}>Export</button>
    //         <small>Data can be exported for a maximum period of 1 month.</small>
    //     </div>`
    //   }
    // }
},
computed: {
	hasFilters() {
		if (Object.keys(this.selectedFilters).length>0) {
			if(this.selectedFilters.hasOwnProperty('multiKey') && !this.selectedFilters.hasOwnProperty('searchValue')) {
				return false;
			}
			return true;
		} else {
			return false;
		}
	},
},
};
</script>

<style lang="scss">
.filter-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		// position: fixed;
		// top: rem(86px);
		// right: rem(20px);
		// z-index: 10;
		button {
			i {
				margin: 0 !important;
				padding: 0 !important;
			}
			span {
				display: none;
			}
		}
		.b-sidebar-body {
			height: calc(100% - 86px);
		}
	}

	&.billing-sidebar {
		position: relative !important;
		top: 0;
		right: 0;
		z-index: 8;
	}
}
</style>


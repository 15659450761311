var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutsideMenu,
          expression: "clickOutsideMenu"
        }
      ],
      staticClass: "order-menu"
    },
    [
      _c(
        "b-button",
        {
          staticClass: "btn-icon-transparent px-16",
          on: { click: _vm.toggleOrderMenu }
        },
        [_c("i", { staticClass: "icon-more fs16" })]
      ),
      _c(
        "ul",
        {
          staticClass: "order-menu__list",
          class: { "order-menu__list--collapsed": _vm.orderMenuToggle }
        },
        [
          _c(
            "li",
            [
              _c("b-link", { on: { click: _vm.cancelModal } }, [
                _c("i", { staticClass: "icon-cancel fs20 pr-12 text-blue" }),
                _c("span", { staticClass: "fs14 lh24" }, [
                  _vm._v(" Cancel Order ")
                ])
              ])
            ],
            1
          ),
          !_vm.hidefullEdit
            ? _c(
                "li",
                [
                  _c(
                    "b-link",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            path: "orders/" + _vm.orderID,
                            query: { fullEdit: true }
                          })
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "icon-edit fs20 pr-12 text-blue"
                      }),
                      _c("span", { staticClass: "fs14 lh24" }, [
                        _vm._v(" Full Edit ")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative pt-64 pt-md-80" },
    [
      _c(
        "div",
        { staticClass: "filter-wrap" },
        [
          _c(
            "ul",
            { staticClass: "filter-list shipments" },
            _vm._l(_vm.filters, function(filter) {
              return _c(
                "li",
                {
                  key: filter.title,
                  class: { active: _vm.selectedFilter == filter.id },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.pageTab(filter.id)
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn-filter",
                      attrs: {
                        href: "#",
                        id: filter.id,
                        fields: _vm.computedFileds
                      },
                      on: {
                        click: function($event) {
                          return _vm.pageTab(filter.id)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(filter.label || filter.title) + " ")]
                  )
                ]
              )
            }),
            0
          ),
          _vm.activeTab == 0
            ? _c("orderSideFilter", {
                ref: "filter",
                staticClass: "pl-20 pl-lg-0"
              })
            : _vm._e(),
          _vm.activeTab != 0
            ? _c("shipmentFilterSidebar", {
                ref: "filter",
                staticClass: "pl-20 pl-lg-0",
                on: { shipmentExport: _vm.shipmentExport }
              })
            : _vm._e()
        ],
        1
      ),
      _c("transition", [
        _vm.selectedRows.length > 0
          ? _c("div", { staticClass: "select-bar filters row z-99" }, [
              _c(
                "div",
                {
                  staticClass:
                    "container-fluid d-flex justify-content-between align-items-center h70px"
                },
                [
                  _c(
                    "span",
                    { staticClass: "d-none d-md-flex text-gray-900 wsnw" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selectedRows.length) +
                          " " +
                          _vm._s(
                            _vm.selectedRows.length == 1 ? "Order" : "Orders"
                          ) +
                          " selected "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-auto hidden-sm-down" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-8 mr-md-16",
                          on: { click: _vm.clearSelected }
                        },
                        [_vm._v(" Clear ")]
                      ),
                      _vm.activeTab == 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "errorbtn mr-8 mr-md-16",
                              on: { click: _vm.openbulkCancel }
                            },
                            [_vm._v(" Cancel Orders ")]
                          )
                        : _vm._e(),
                      _vm.activeTab == 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-8 mr-md-16",
                              on: { click: _vm.openEditModal }
                            },
                            [
                              _c("i", { staticClass: "icon-edit fs16 pr-10" }),
                              _vm._v(" Edit Orders ")
                            ]
                          )
                        : _vm._e(),
                      _vm.activeTab == 0
                        ? _c(
                            "b-button",
                            {
                              attrs: { disabled: _vm.loader.booking },
                              on: {
                                click: function($event) {
                                  return _vm.bookFromTable("")
                                }
                              }
                            },
                            [
                              _vm._v(" Book "),
                              _vm.loader.booking
                                ? _c("b-spinner", { staticClass: "sm" })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.activeTab == 4 ||
                      _vm.activeTab == 1 ||
                      _vm.activeTab == 2 ||
                      _vm.activeTab == 3
                        ? _c(
                            "b-button",
                            {
                              staticClass: "errorbtn mr-8 mr-md-16",
                              on: { click: _vm.bulkCancelShipmentsSelected }
                            },
                            [_vm._v(" Cancel Shipments ")]
                          )
                        : _vm._e(),
                      _vm.activeTab == 2 &&
                      _vm.bulkLabelAllowedSellers.includes(_vm.user.id)
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "m-md-2 float-right",
                              attrs: {
                                right: "",
                                disabled: _vm.loader.printing,
                                id: "dropdown-1",
                                text: "Print Label"
                              }
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.bulkSlip(2)
                                    }
                                  }
                                },
                                [_vm._v("Download Label (upto 100)")]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.bulkSlip(1)
                                    }
                                  }
                                },
                                [_vm._v("Email Bulk All Booked (Single PDF)")]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.bulkSlip(0)
                                    }
                                  }
                                },
                                [_vm._v("Email Bulk All Booked (Multi PDF)")]
                              )
                            ],
                            1
                          )
                        : _vm.activeTab == 2 ||
                          _vm.activeTab == 4 ||
                          _vm.activeTab == 1 ||
                          _vm.activeTab == 3
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-8 mr-md-16",
                              attrs: {
                                disabled: _vm.loader.printing,
                                variant: "secondary"
                              },
                              on: { click: _vm.bulkSlip }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-download mr-10 fs16"
                              }),
                              _vm._v(" Print Label "),
                              _vm.loader.printing
                                ? _c("b-spinner", { staticClass: "sm" })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.activeTab == 9
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "secondary" },
                              on: { click: _vm.rtoReceived }
                            },
                            [_vm._v(" RTO-Received ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "visible-sm d-flex" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-16 fs14 fw400 text-gray-900 h40px",
                          attrs: { size: "xs" },
                          on: { click: _vm.openEditModal }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-edit text-blue fs16 mr-6"
                          }),
                          _vm._v(" Edit ")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-16 fs14 fw400 text-gray-900 h40px",
                          attrs: { size: "xs" },
                          on: { click: _vm.openCancelModal }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-cancel text-blue fs16 mr-6"
                          }),
                          _vm._v(" Cancel ")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "fw400 fs14 text-gray-900 h40px",
                          attrs: { variant: "secondary", size: "xs" },
                          on: { click: _vm.downloadToast }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-download text-blue fs16 mr-6"
                          }),
                          _vm._v(" Download ")
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      _c("transition", [
        _vm.filterResultsToggle && _vm.selectedRows.length == 0
          ? _c("div", { staticClass: "select-bar d-flex shipments" }, [
              _c(
                "div",
                { staticClass: "container-fluid d-flex align-items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          this.selFilters.length != "0"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-none d-md-flex mr-6 text-gray-600 wsnw"
                                },
                                [_vm._v(" Results for: ")]
                              )
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "d-none d-md-flex result-list" },
                            [
                              _vm._l(_vm.selFilters, function(
                                selFilter,
                                index
                              ) {
                                return _c(
                                  "li",
                                  { key: index },
                                  [
                                    !(
                                      selFilter == "AWB" ||
                                      selFilter == "OrderID" ||
                                      selFilter == "ShipmentID" ||
                                      index == "filtering"
                                    )
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "mx-4 my-1",
                                            attrs: { variant: "tag" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "wsnw text-truncate"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      selFilter == "true"
                                                        ? "Sort By OrderID"
                                                        : selFilter.includes(
                                                            "\n"
                                                          ) ||
                                                          selFilter.includes(
                                                            ","
                                                          )
                                                        ? "Multiple"
                                                        : selFilter
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c("i", {
                                              staticClass: "icon-close fs6",
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeFilter(index)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "li",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mx-4 my-1",
                                      attrs: { variant: "tag" }
                                    },
                                    [
                                      _c("span", { staticClass: "wsnw" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.filters[_vm.activeTab].title
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          this.selFilters.length != "0"
                            ? _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(this.selFilters.length) +
                                    " Filters selected. "
                                )
                              ])
                            : _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(" No filters selected. ")
                              ]),
                          _c(
                            "b-link",
                            {
                              staticClass: "ml-24 mr-12 vam",
                              on: {
                                click: function($event) {
                                  return _vm.clearFilters()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-gray-900 wsnw" },
                                [_vm._v("Clear filters")]
                              ),
                              _c("i", {
                                staticClass:
                                  "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          ((_vm.selFilters.dateEnd &&
                            _vm.daysFromDates() <= 30) ||
                            (_vm.selFilters.multiKey &&
                              _vm.selFilters.searchValue)) &&
                          _vm.items.length > 0
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left",
                                      modifiers: { hover: true, left: true }
                                    }
                                  ],
                                  staticClass: "wsnw",
                                  attrs: {
                                    title:
                                      _vm.activeTab != 0
                                        ? "Shipments Export will be sent to your Registered and Operation Email"
                                        : "",
                                    disabled: _vm.loader.exporting,
                                    variant: "secondary"
                                  },
                                  on: { click: _vm.shipmentExportButton }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-download fs16"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-none d-md-inline ml-0 ml-md-10"
                                    },
                                    [
                                      _vm._v(" Export "),
                                      _vm.loader.exporting
                                        ? _c("b-spinner", { staticClass: "sm" })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.sidebarFilters",
                                  modifiers: { sidebarFilters: true }
                                }
                              ],
                              staticClass:
                                "d-none text-gray-900 d-md-flex ml-8",
                              attrs: { variant: "secondary" }
                            },
                            [
                              _vm._v(" Filters "),
                              _c("i", {
                                staticClass: "icon-filter text-gray-600 pl-10"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                ref: "selectableTable",
                staticClass: "shipments-table table-sm-cards",
                attrs: {
                  busy: _vm.isBusy,
                  items: _vm.items,
                  fields: _vm.computedFileds,
                  "tbody-tr-class": _vm.tbodyRowClass,
                  "per-page": _vm.perPage,
                  "sticky-header": "",
                  "no-border-collapse": ""
                },
                on: { "row-clicked": _vm.tbodyRowAttr },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "head(selected)",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-checkbox", {
                                attrs: {
                                  indeterminate:
                                    _vm.allSelected &&
                                    _vm.selectedRows.length != _vm.items.length
                                },
                                on: { change: _vm.toggleAllRows },
                                model: {
                                  value: _vm.allSelected,
                                  callback: function($$v) {
                                    _vm.allSelected = $$v
                                  },
                                  expression: "allSelected"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(selected)",
                      fn: function(ref) {
                        var item = ref.item
                        var key = ref.field.key
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center w-sm-50 align-items-center"
                            },
                            [
                              _vm.activeTab == 0
                                ? _c("b-checkbox", {
                                    ref: item.id,
                                    attrs: {
                                      disabled:
                                        item.status != 0 ||
                                        item.valid == false ||
                                        item.channelID == 9 ||
                                        item.hasError
                                    },
                                    on: { input: _vm.orderPersist },
                                    model: {
                                      value: item[key],
                                      callback: function($$v) {
                                        _vm.$set(item, key, $$v)
                                      },
                                      expression: "item[key]"
                                    }
                                  })
                                : _vm._e(),
                              _vm.activeTab != 0 && _vm.activeTab != 9
                                ? _c("b-checkbox", {
                                    attrs: {
                                      disabled:
                                        item.status != 0 ||
                                        item.isCancelled != 0 ||
                                        item.awb == "N/A"
                                    },
                                    on: { input: _vm.shipmentPersist },
                                    model: {
                                      value: item[key],
                                      callback: function($$v) {
                                        _vm.$set(item, key, $$v)
                                      },
                                      expression: "item[key]"
                                    }
                                  })
                                : _vm._e(),
                              _vm.activeTab == 9
                                ? _c("b-checkbox", {
                                    attrs: {
                                      disabled:
                                        item.status != 7 ||
                                        item.isCancelled != 0 ||
                                        item.awb == "N/A"
                                    },
                                    model: {
                                      value: item[key],
                                      callback: function($$v) {
                                        _vm.$set(item, key, $$v)
                                      },
                                      expression: "item[key]"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(orderId)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _vm.activeTab == 0
                                ? _c(
                                    "b-link",
                                    {
                                      staticClass: "secondary order",
                                      attrs: { to: "/orders/" + data.item.id }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.item.importedID) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                : _c(
                                    "b-link",
                                    {
                                      staticClass: "secondary order",
                                      attrs: {
                                        to: "/shipments/" + data.item.id
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.item.importedID) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "fs12 lh16 text-gray-600 text-capitalize"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.item.channelName == "MPS"
                                          ? "MPS"
                                          : data.item.channelName == "API"
                                          ? "API"
                                          : String(
                                              data.item.channelName
                                            ).toLowerCase()
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(date)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "w74 d-block" }, [
                            _vm._v(
                              " " + _vm._s(_vm._f("date")(data.item.date)) + " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(id)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "d-flex flex-column w72" }, [
                            _vm.activeTab == 0
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(data.item.shipmentID) + " "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(data.item.id) + " ")
                                ]),
                            _vm.activeTab != 0
                              ? _c(
                                  "span",
                                  { staticClass: "fs12 lh16 text-gray-600" },
                                  [
                                    _vm._v(
                                      " Est. ₹ " +
                                        _vm._s(
                                          data.item.estimatedValue
                                            ? data.item.estimatedValue.toFixed(
                                                2
                                              )
                                            : "0.00"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(customer)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column wsnw w130px" },
                            [
                              _c("span", { staticClass: "text-truncate" }, [
                                _vm._v(
                                  " " + _vm._s(data.item.customerName) + " "
                                ),
                                data.item.shortAddress
                                  ? _c("img", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.right",
                                          modifiers: {
                                            hover: true,
                                            right: true
                                          }
                                        }
                                      ],
                                      staticClass: " warning2",
                                      attrs: {
                                        title: "Short Customer Address",
                                        src: require("@/assets/images/caution.png"),
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "fs12 lh16 text-gray-600 text-truncate",
                                  attrs: { title: data.item.customerMeta }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ellipsize(data.item.city)) +
                                      " - " +
                                      _vm._s(data.item.pincode) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(value)",
                      fn: function(data) {
                        return [
                          _c("div", [
                            data.item.channelID == 12
                              ? _c("span", { staticClass: "d-block wsnw" }, [
                                  _vm._v(
                                    " $ " +
                                      _vm._s(data.item.value.toFixed(2)) +
                                      " "
                                  )
                                ])
                              : _c("span", { staticClass: "d-block wsnw" }, [
                                  _vm._v(
                                    " ₹ " +
                                      _vm._s(data.item.value.toFixed(2)) +
                                      " "
                                  )
                                ]),
                            _c(
                              "span",
                              {
                                staticClass: "value-type",
                                class: data.item.type.toLowerCase()
                              },
                              [_vm._v(" " + _vm._s(data.item.type) + " ")]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(warehouse)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column w130px" },
                            [
                              _c("span", { staticClass: "text-truncate" }, [
                                _vm._v(" " + _vm._s(data.item.warehouse) + " ")
                              ]),
                              _c(
                                "span",
                                { staticClass: "fs12 lh16 text-gray-600" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.warehouseCity) +
                                      " - " +
                                      _vm._s(data.item.warehousePostcode) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      }
                    },
                    _vm.activeTab == 0
                      ? {
                          key: "cell(SKUs)",
                          fn: function(data) {
                            return [
                              _c("div", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "wsnw" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(data.item.skuCount) + " "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "icon-disclaimer fs12 text-gray-400",
                                      attrs: { id: "" + data.item.id }
                                    }),
                                    _c(
                                      "b-popover",
                                      {
                                        staticStyle: {
                                          "z-index": "999",
                                          position: "absolute"
                                        },
                                        attrs: {
                                          target: "" + data.item.id,
                                          placement: "bottom",
                                          title: "Popover!",
                                          triggers: "hover focus",
                                          "custom-class":
                                            "mw373 position-absolute z-99"
                                        }
                                      },
                                      _vm._l(data.item.itemPopover, function(
                                        item,
                                        index
                                      ) {
                                        return _c("span", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-gray-600",
                                              staticStyle: {
                                                "min-width": "200px"
                                              }
                                            },
                                            [
                                              index > 0
                                                ? _c("hr", {
                                                    staticClass: "my-10"
                                                  })
                                                : _vm._e(),
                                              _c("div", [
                                                _c("span", [
                                                  _vm._v("Item Name ")
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "float-right"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.itemQuantity
                                                      ) + " Qty."
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-normal"
                                                },
                                                [_vm._v(_vm._s(item.itemName))]
                                              )
                                            ]
                                          )
                                        ])
                                      }),
                                      0
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-flex flex-column fs12 lh16 text-gray-600"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.item.quantity) +
                                        " Qty. "
                                    )
                                  ]
                                )
                              ])
                            ]
                          }
                        }
                      : null,
                    {
                      key: "cell(dimensions)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  parseFloat(
                                    data.item.weight != null
                                      ? data.item.weight
                                      : 0
                                  ).toFixed(2)
                                ) + " kg"
                              )
                            ]),
                            _c(
                              "span",
                              { staticClass: "fs12 lh16 text-gray-800" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.item.multiple
                                        ? "Multiple"
                                        : parseInt(
                                            data.item.length != null
                                              ? data.item.length
                                              : 0
                                          ) +
                                            "x" +
                                            parseInt(
                                              data.item.width != null
                                                ? data.item.width
                                                : 0
                                            ) +
                                            "x" +
                                            parseInt(
                                              data.item.height != null
                                                ? data.item.height
                                                : 0
                                            )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(mode)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "wsnw" }, [
                              _vm._v(_vm._s(data.item.modeValue))
                            ]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs12 lh16 carrier font-weight-bold",
                                attrs: { "data-carrier": data.item.carrierID }
                              },
                              [_vm._v(" " + _vm._s(data.item.carrier) + " ")]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(awb)",
                      fn: function(data) {
                        return [
                          data.item.awb != "N/A"
                            ? _c(
                                "b-link",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: data.item.awb,
                                      expression: "data.item.awb",
                                      arg: "copy"
                                    },
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true }
                                    }
                                  ],
                                  staticClass:
                                    "cp underline-dashed text-gray-800 text-decoration-none",
                                  attrs: { title: "Click to copy!" },
                                  on: { click: _vm.copySuccess }
                                },
                                [_vm._v(" " + _vm._s(data.item.awb) + " ")]
                              )
                            : _c("span", { staticClass: "text-gray-800" }, [
                                _vm._v("N/A")
                              ])
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center",
                              class: { "mr-12": _vm.activeTab == 0 }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "" +
                                    (_vm.activeTab == 0 ? "w80px" : "w120px")
                                },
                                [
                                  data.item.button &&
                                  _vm.activeTab == 0 &&
                                  data.item.finalStatus == "Book" &&
                                  !data.item.hasError
                                    ? [
                                        _c(
                                          "b-button",
                                          {
                                            class: {
                                              "bg-white text-blue shadow":
                                                data.item.hasDimension == false
                                            },
                                            attrs: {
                                              variant:
                                                "primary btn-xs fw700 w85px",
                                              disabled:
                                                _vm.disableSinglebook ||
                                                data.item.buttonDisabled
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.orderBookFromRow(
                                                  data.item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" Book ")]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm.activeTab == 0 && data.item.hasError
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex",
                                            staticStyle: { width: "7.5rem" }
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant:
                                                    "primary btn-xs fw700 w85px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$router.push({
                                                      path:
                                                        "orders/" +
                                                        data.item.id,
                                                      query: { fullEdit: true }
                                                    })
                                                  }
                                                }
                                              },
                                              [_vm._v(" Edit ")]
                                            ),
                                            _c("img", {
                                              staticClass: "w-25 warning1",
                                              attrs: {
                                                id: "" + data.item.importedID,
                                                src: require("@/assets/images/warning1.png"),
                                                alt: ""
                                              }
                                            }),
                                            _c(
                                              "b-popover",
                                              {
                                                attrs: {
                                                  target:
                                                    "" + data.item.importedID,
                                                  placement: "righttop",
                                                  title: "Popover!",
                                                  triggers: "hover focus",
                                                  "custom-class": "mw373"
                                                }
                                              },
                                              [
                                                data.item.errors.length > 1
                                                  ? _c(
                                                      "ol",
                                                      _vm._l(
                                                        data.item.errors,
                                                        function(item, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "text-gray-600"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(item) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._l(
                                                      data.item.errors,
                                                      function(item, index) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-gray-600"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " + _vm._s(item)
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm.activeTab == 0 &&
                                  data.item.finalStatus != "Book" &&
                                  !data.item.hasError
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tag",
                                            class: _vm._f("lowercase")(
                                              data.item.finalStatus
                                            )
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.finalStatus) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm.activeTab != 0
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tag mr-10",
                                            class: _vm._f("lowercase")(
                                              data.item.finalShipmentStatus
                                            )
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.item.finalShipmentStatus
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  data.item.status == 0 &&
                                  data.item.isCancelled == 0 &&
                                  data.item.awb != "N/A" &&
                                  _vm.activeTab != 0 &&
                                  _vm.activeTab != 9 &&
                                  data.item.type != "reverse"
                                    ? [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mr-10",
                                            attrs: {
                                              variant: "primary btn-xs"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.singleSlipDownload(
                                                  data.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "icon-download fs12"
                                            })
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm.activeTab == 0 && !data.item.hasError
                                ? [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          disabled:
                                            data.item.hidequickedit ||
                                            (data.item.button &&
                                            data.item.finalStatus == "Book"
                                              ? false
                                              : true)
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleDetails(data.item)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-edit-quick fs20 ml-20 p-0 text-blue",
                                          class: {
                                            "text-gray-400":
                                              data.item.hidequickedit ||
                                              (data.item.button &&
                                              data.item.finalStatus == "Book"
                                                ? false
                                                : true)
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                : _vm._e(),
                              _vm.activeTab == 0 &&
                              data.item.status != 9 &&
                              data.item.status != 2 &&
                              data.item.status != 8
                                ? _c("ShipmentsTableDropdown", {
                                    ref: "ShipmentsTableDropdown",
                                    staticClass: "d-inline-block",
                                    class: { "ml-40": data.item.hasError },
                                    attrs: {
                                      orderID: data.item.id,
                                      hidefullEdit: data.item.hidequickedit
                                    },
                                    on: {
                                      toggleCancel: function($event) {
                                        return _vm.openCancelModal(data.item.id)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      }
                    },
                    {
                      key: "row-details",
                      fn: function(row) {
                        return [
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.formSubmit(row.item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "expanded-row" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "fs12 lh14 ml-10 mr-8" },
                                    [_vm._v("MODE")]
                                  ),
                                  _c("multiselect", {
                                    staticClass: "select-xs w120px",
                                    attrs: {
                                      id: "mode",
                                      options: row.item.modeList,
                                      title: "Mode Type",
                                      label: "name",
                                      searchable: false,
                                      "show-labels": false,
                                      required: ""
                                    },
                                    model: {
                                      value: row.item.mode,
                                      callback: function($$v) {
                                        _vm.$set(row.item, "mode", $$v)
                                      },
                                      expression: "row.item.mode"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "fs12 lh14 ml-14 mr-8" },
                                    [_vm._v("LENGTH (CM)")]
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "small w50px",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "1",
                                      max: "999",
                                      required: ""
                                    },
                                    model: {
                                      value: row.item.length,
                                      callback: function($$v) {
                                        _vm.$set(row.item, "length", $$v)
                                      },
                                      expression: "row.item.length"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "fs12 lh14 ml-14 mr-8" },
                                    [_vm._v("WIDTH (CM)")]
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "small w50px",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "1",
                                      max: "999",
                                      required: ""
                                    },
                                    model: {
                                      value: row.item.width,
                                      callback: function($$v) {
                                        _vm.$set(row.item, "width", $$v)
                                      },
                                      expression: "row.item.width"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "fs12 lh14 ml-14 mr-8" },
                                    [_vm._v("HEIGHT (CM)")]
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "small w50px",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "1",
                                      max: "999",
                                      required: ""
                                    },
                                    model: {
                                      value: row.item.height,
                                      callback: function($$v) {
                                        _vm.$set(row.item, "height", $$v)
                                      },
                                      expression: "row.item.height"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "fs12 lh14 ml-14 mr-8" },
                                    [_vm._v("WEIGHT (KG)")]
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "small w50px",
                                    attrs: {
                                      placeholder: "0.00",
                                      type: "number",
                                      step: "0.01",
                                      required: "",
                                      min: _vm.$store.state.minW,
                                      max: _vm.$store.state.maxW
                                    },
                                    model: {
                                      value: row.item.weight,
                                      callback: function($$v) {
                                        _vm.$set(row.item, "weight", $$v)
                                      },
                                      expression: "row.item.weight"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "fs12 lh14 ml-14 mr-8" },
                                    [_vm._v("WAREHOUSE")]
                                  ),
                                  _c("multiselect", {
                                    staticClass: "select-xs mw170",
                                    attrs: {
                                      id: "mode",
                                      options: _vm.warehousesList,
                                      label: "name",
                                      required: "",
                                      searchable: false,
                                      "show-labels": false
                                    },
                                    model: {
                                      value: row.item.warehouseName,
                                      callback: function($$v) {
                                        _vm.$set(row.item, "warehouseName", $$v)
                                      },
                                      expression: "row.item.warehouseName"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ml-auto" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "px-24 mr-12 quickedit-book",
                                          attrs: {
                                            variant: "tertiary",
                                            size: "xs",
                                            type: "submit"
                                          }
                                        },
                                        [_vm._v(" Save & Book ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "px-8",
                                          attrs: {
                                            variant:
                                              "icon-transparent text-blue"
                                          },
                                          on: { click: row.toggleDetails }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-close fs10"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(items) {
              return _c(
                "li",
                { key: items.id, staticClass: "d-flex flex-column" },
                [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c(
                      "span",
                      {
                        staticClass: "d-block fw500 mb-4 cp",
                        on: {
                          click: function($event) {
                            return _vm.navigate(items.id)
                          }
                        }
                      },
                      [
                        _c("strong", [_vm._v("Order ID: ")]),
                        _vm._v(" " + _vm._s(items.importedID) + " ")
                      ]
                    ),
                    _vm.activeTab != 0
                      ? _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "tag mr-10",
                              class: _vm._f("lowercase")(
                                items.finalShipmentStatus
                              )
                            },
                            [
                              _vm._v(
                                " " + _vm._s(items.finalShipmentStatus) + " "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("span", { staticClass: "mb-4" }, [
                    _c("strong", [_vm._v("Date: ")]),
                    _vm._v(" " + _vm._s(_vm._f("date")(items.date)) + " ")
                  ]),
                  _c("span", { staticClass: "carrier-mode mb-4" }, [
                    _c("strong", [_vm._v("Mode: ")]),
                    _vm._v(" " + _vm._s(items.modeValue) + " ")
                  ]),
                  _c("span", { staticClass: "carrier-name mb-4" }, [
                    _c("strong", [_vm._v("Carrier:")]),
                    _vm._v(" " + _vm._s(items.carrier) + " ")
                  ]),
                  items.awb && _vm.activeTab != 0
                    ? _c("span", { staticClass: "mb-4" }, [
                        _c("strong", [_vm._v("AWB: ")]),
                        _vm._v(" " + _vm._s(items.awb) + " ")
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("div", [
                      items.channelID == 12
                        ? _c("span", { staticClass: "d-block wsnw mb-4" }, [
                            _vm._v(" $" + _vm._s(items.value.toFixed(2)) + " ")
                          ])
                        : _c("span", { staticClass: "d-block wsnw mb-4" }, [
                            _vm._v(" ₹" + _vm._s(items.value.toFixed(2)) + " ")
                          ]),
                      _c(
                        "span",
                        {
                          staticClass: "value-type",
                          class: items.type.toLowerCase()
                        },
                        [_vm._v(" " + _vm._s(items.type) + " ")]
                      )
                    ]),
                    _c("div", [
                      items.button &&
                      _vm.activeTab == 0 &&
                      items.finalStatus == "Book"
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-button",
                                {
                                  class: {
                                    "bg-white text-blue shadow":
                                      items.hasDimension == false
                                  },
                                  attrs: {
                                    variant: "primary btn-xs fw700 w85px",
                                    disabled:
                                      _vm.disableSinglebook ||
                                      items.buttonDisabled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.orderBookFromRow(items)
                                    }
                                  }
                                },
                                [_vm._v(" Book ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.activeTab == 0 && items.finalStatus != "Book"
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "tag",
                                class: _vm._f("lowercase")(items.finalStatus)
                              },
                              [_vm._v(" " + _vm._s(items.finalStatus) + " ")]
                            )
                          ])
                        : _vm._e(),
                      items.status == 0 &&
                      items.isCancelled == 0 &&
                      items.awb != "N/A" &&
                      _vm.activeTab != 0 &&
                      _vm.activeTab != 9 &&
                      items.type != "reverse"
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-10",
                                  attrs: { variant: "primary btn-xs" },
                                  on: {
                                    click: function($event) {
                                      return _vm.singleSlipDownload(items.id)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "icon-download fs12" })]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              )
            }),
            0
          ),
      !_vm.isBusy
        ? _c("div", { staticClass: "pagination-bar" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex wsnw align-items-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-block w50px" },
                        [
                          _c("multiselect", {
                            staticClass: "pagination-items__pp",
                            attrs: {
                              searchable: false,
                              "show-labels": false,
                              "allow-empty": false,
                              options: _vm.pageOptions
                            },
                            on: { input: _vm.setPageNumber },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", {
                        staticClass:
                          "pl-8 fs12 lh24 text-gray-600 pagination-items"
                      })
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-8 text-right" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-flex ml-auto wsnw align-items-center"
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "fs12 lh24 text-gray-600 pr-14" },
                        [_vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")]
                      ),
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.prevDisabled,
                                variant: "paginate left"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.prevPage()
                                }
                              }
                            },
                            [_c("i", { staticClass: "icon-back fs12" })]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: _vm.nextDisabled,
                                variant: "paginate right"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.nextPage()
                                }
                              }
                            },
                            [_c("i", { staticClass: "icon-arrow fs12" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("EditOrderModal", {
        ref: "editOrderModal",
        attrs: {
          editOrderID: _vm.editOrderID,
          selectedImportedID: _vm.selectedImportedID,
          skuOrderID: _vm.skuOrderID,
          selectedEditOrder: _vm.selectedEditOrder,
          orderIDs: _vm.orderIDs,
          saveSKUInTable: _vm.saveSKUInTable
        },
        on: {
          "update:editOrderID": function($event) {
            _vm.editOrderID = $event
          },
          "update:edit-order-i-d": function($event) {
            _vm.editOrderID = $event
          },
          bookFromTable: _vm.bookFromTable,
          "update:selectedImportedID": function($event) {
            _vm.selectedImportedID = $event
          },
          "update:selected-imported-i-d": function($event) {
            _vm.selectedImportedID = $event
          },
          "update:selectedEditOrder": function($event) {
            _vm.selectedEditOrder = $event
          },
          "update:selected-edit-order": function($event) {
            _vm.selectedEditOrder = $event
          },
          "update:saveSKUInTable": function($event) {
            _vm.saveSKUInTable = $event
          },
          "update:save-s-k-u-in-table": function($event) {
            _vm.saveSKUInTable = $event
          }
        }
      }),
      _c("choiceDialog", {
        ref: "choiceDialog",
        attrs: {
          bookOrderFlag: _vm.bookOrderFlag,
          formInfo: _vm.formInfo,
          choiceData: _vm.choiceData
        },
        on: { openModal: _vm.openPricingModal }
      }),
      _c("Bulkbooking", {
        ref: "bulkbooking",
        attrs: { bulkbookingArr: _vm.bulkbookingArr }
      }),
      _c("ShipmentsCancelModal", {
        ref: "cancelOrderModal",
        attrs: { orderID: _vm.cancelOrderID }
      }),
      _c("BulkCancel", {
        ref: "bulkCancel",
        attrs: { selectedCancelOrders: _vm.selectedCancelOrders }
      }),
      _c("bulkCancelShipments", {
        ref: "bulkCancelShipments",
        attrs: { selectedShipments: _vm.selectedShipments }
      }),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="shipments">
		<AppTopbar
		:title="title"
		buttonText="New Order"
		buttonLink="new"
		buttonIconClass="icon-add"
		></AppTopbar>
		<ShipmentsTable ref="ShipmentsTable" />
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import ShipmentsTable from "@/components/ShipmentsTable.vue";

export default {
	name: "ShipmentsList",
	title: "Shyplite - Shipments",
	components: {
		AppTopbar,
		ShipmentsTable
	},
	data() {
		return {
			title: 'Orders'
		}
	},
	watch: {
		'$route.query': {
			handler: function(query) {
				this.title = query.tab == 0 ? 'Orders' : 'Shipments';
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
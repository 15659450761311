var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "cancelOrderModal",
      attrs: {
        centered: "",
        title: "Cancel Order",
        id: "cancelOrderModal",
        "modal-class": "cancel-modal"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "fs18 lh28 fw500" }, [
                _vm._v(
                  " Cancel " +
                    _vm._s(_vm.selectedShipments.length) +
                    " Selected Shipment" +
                    _vm._s(_vm.selectedShipments.length > 1 ? "s" : "") +
                    " "
                )
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant fw400" })]
              )
            ]
          }
        },
        {
          key: "modal-footer",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "b-button",
                {
                  staticClass: "m-0 mr-8",
                  attrs: {
                    variant: "secondary-modal w-sm-100 mb-12 mb-md-0",
                    disabled: _vm.isBusy
                  },
                  on: { click: _vm.cancelFromTable }
                },
                [
                  _vm._v(
                    " Yes, Cancel Shipment" +
                      _vm._s(_vm.selectedShipments.length > 1 ? "s" : "") +
                      " "
                  ),
                  _vm.isBusy ? _c("b-spinner", { staticClass: "sm" }) : _vm._e()
                ],
                1
              ),
              !_vm.isBusy
                ? _c(
                    "b-button",
                    {
                      staticClass: "m-0",
                      attrs: { id: "btnHide", variant: "primary w-sm-100" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_vm._v(" No, lead me back ")]
                  )
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("p", { staticClass: "pt-20 pt-md-20 text-gray-800" }, [
        _vm._v(
          " Are you sure you want to cancel " +
            _vm._s(_vm.selectedShipments.length > 1 ? "these" : "this") +
            " Shipment" +
            _vm._s(_vm.selectedShipments.length > 1 ? "s" : "") +
            "? "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="order-menu" v-click-outside="clickOutsideMenu">
    <b-button @click="toggleOrderMenu" class="btn-icon-transparent px-16">
      <i class="icon-more fs16"></i>
    </b-button>
    <ul
      class="order-menu__list"
      :class="{ 'order-menu__list--collapsed': orderMenuToggle }"
    >
      <li>
        <b-link @click="cancelModal">
          <i class="icon-cancel fs20 pr-12 text-blue"></i>
          <span class="fs14 lh24">
            Cancel Order
          </span>
        </b-link>
      </li>
      <li v-if="!hidefullEdit">
        <b-link @click="$router.push({path: 'orders/' + orderID, query: {'fullEdit' : true}})">
          <i class="icon-edit fs20 pr-12 text-blue"></i>
          <span class="fs14 lh24">
            Full Edit
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "ShipmentsTableDropdown",
  props: ["orderID","hidefullEdit"],
  data() {
    return {
      orderMenuToggle: true,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    toggleOrderMenu() {
      this.orderMenuToggle = !this.orderMenuToggle;
    },
    clickOutsideMenu() {
      this.orderMenuToggle = true;
    },
    downloadToast() {
      this.popToast(
        "requested",
        "Download requested",
        "Your download should start now."
      );
      this.orderMenuToggle = true;
    },
    cancelModal() {
      this.$emit("toggleCancel");
      this.orderMenuToggle = true;
    },
  },
};
</script>

<style lang="css" scoped>
  .order-menu__list {
    top: -15px;
  }
</style>
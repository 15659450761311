var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { id: "infoModal", centered: "", "hide-footer": "", size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "div",
                { staticClass: " text-center", staticStyle: { width: "100%" } },
                [
                  _c("span", {
                    staticClass: "d-block mt-auto float-left fs24 lh28 fw500"
                  })
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "b-button",
        {
          staticClass: "float-right mt-20",
          attrs: { size: "sm", variant: "rounded" },
          on: {
            click: function($event) {
              return _vm.close()
            }
          }
        },
        [_c("i", { staticClass: "icon-close-variant" })]
      ),
      _c("div", { staticClass: "col row d-flex justify-content-around" }, [
        _c("div", { staticClass: "col-md-12 d-flex justify-content-around" }, [
          _c("img", {
            staticStyle: { height: "3rem", width: "3rem" },
            attrs: { src: require("@/assets/images/warning1.png"), alt: "" }
          })
        ]),
        _c(
          "p",
          { staticClass: "col-md-12 d-flex justify-content-around fs18 mt-15" },
          [_c("strong", [_vm._v("Account Balance Low")])]
        ),
        _c(
          "p",
          {
            staticClass: "col-md-12 d-flex justify-content-around mt-20 fs16",
            staticStyle: { display: "block" }
          },
          [_c("strong", [_vm._v("Your orders may not be booked")])]
        )
      ]),
      _c(
        "div",
        { staticClass: "pt-40 pt-md-40 text-center" },
        [
          _c(
            "b-button",
            {
              attrs: { disabled: _vm.booking, variant: "tertiary", size: "lg" },
              on: { click: _vm.bookBulk }
            },
            [_vm.booking ? _c("b-spinner") : _vm._e(), _vm._v(" Continue ")],
            1
          ),
          _c("span", { staticClass: "mx-10" }),
          _c(
            "b-button",
            {
              attrs: { variant: "primary", size: "lg" },
              on: { click: _vm.openAddfunds }
            },
            [_vm._v(" Add Funds ")]
          )
        ],
        1
      ),
      _c("BillingAddFunds", { ref: "BillingAddFunds" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-modal
    id="infoModal"
    centered
    v-model="isOpen"
    hide-footer
    size="lg"
  >
    <template v-slot:modal-header="{ close }">
      <div class=" text-center" style="width: 100%;">
        <span class="d-block mt-auto float-left fs24 lh28 fw500">
          
        </span>
      </div>
    </template>
    <b-button class="float-right mt-20" size="sm" variant="rounded" @click="close()">
          <i class="icon-close-variant"></i>
        </b-button>
     <div class="col row d-flex justify-content-around">
      <div class="col-md-12 d-flex justify-content-around">
        <img src="@/assets/images/warning1.png" style="height:3rem;width:3rem;" alt="">
      </div>
      <p class="col-md-12 d-flex justify-content-around fs18 mt-15"><strong>Account Balance Low</strong></p>
      <p class="col-md-12 d-flex justify-content-around mt-20 fs16" style="display:block"><strong>Your orders may not be booked</strong></p>
    </div>



    <div class="pt-40 pt-md-40 text-center">
      <b-button :disabled="booking" @click="bookBulk" variant="tertiary" size="lg">
        <b-spinner v-if="booking"></b-spinner>
        Continue
      </b-button>
      <span class="mx-10"></span>
      <b-button @click="openAddfunds" variant="primary" size="lg">
        Add Funds
      </b-button>
    </div>

  <BillingAddFunds ref="BillingAddFunds" />
  </b-modal>
</template>

<script>
import BillingAddFunds from "@/components/BillingAddFunds.vue";

export default {
  name: "Bulkbooking",
  props:['bulkbookingArr'],
  components: {
    BillingAddFunds
  },
  data() {
    return {
      isOpen: this.modalOpen,
      selectedEditOrder:[],
      booking:false
    };
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.isOpen = false
    },
    async bookBulk() {
      this.booking = true
      try {
        let result = await this.axios.post('/bulkorder', {
            orders: this.$attrs.bulkbookingArr
        })

        if (result.data && result.data.success == true) {
            this.popToast("booked", "Order booked", result.data.message);
            this.isOpen = false
            this.$parent.orderPageData();
        }
        else {
            this.popToast("failed", "Booking error!", result.data.message);
            this.isOpen = false
            this.$parent.orderPageData();
        }
        this.booking = false
      } catch(e) {
        // statements
        console.log(e);
      }
    },
    openAddfunds() {
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    modalData() {
      return Object.assign({},this.$attrs.reminderData)
    }
  }
};
</script>

<style lang="css" scoped>
  /deep/.modal-header {
      padding: unset
    }

    /deep/ .modal-lg {
      max-width: 43.25rem !important;
    }

    .plan {
          position: relative;
          top: 1rem;
    } 
 
 
    .box {
      color: #2D374B !important;
      padding: 1.5rem;
      text-align: center;
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      box-shadow: 0 6px 10px rgb(0 0 0 / 15%);
      /*transition: all 0.3s ease-in-out;*/
    }

/* Create the hidden pseudo-element */
/* include the shadow for the end state */
.box::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: max-content;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  /*transition: opacity 0.3s ease-in-out;*/
}

/* The fast way */

.make-it-fast {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}


/* Pre-render the bigger shadow, but hide it */

.make-it-fast::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}


/* Transition to showing the bigger shadow on hover */

.make-it-fast:hover::after {
  opacity: 1;
}

/* Scale up the box */
/*.box:hover {
  transform: scale(1.2, 1.2);
}*/

/* Fade in the pseudo-element with the bigger shadow */
/*.box:hover::after {
  opacity: 1;
}*/

</style>

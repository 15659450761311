<template>
	<div class="container-fluid position-relative pt-64 pt-md-80">
		<div class="filter-wrap">
			<ul class="filter-list shipments">
				<li v-for="filter in filters" :key="filter.title" @click.prevent="pageTab(filter.id)" :class="{ active: selectedFilter == filter.id }">
					<a href="#" :id="filter.id" @click="pageTab(filter.id)" :fields="computedFileds" class="btn-filter">
						{{ filter.label || filter.title }}
					</a>
				</li>
			</ul>

			<orderSideFilter v-if="activeTab == 0"
			ref="filter"
			class="pl-20 pl-lg-0"
			></orderSideFilter>

			<shipmentFilterSidebar v-if="activeTab != 0"
			ref="filter"
			@shipmentExport="shipmentExport"
			class="pl-20 pl-lg-0"
			></shipmentFilterSidebar>
		</div>

		<transition>
			<div class="select-bar filters row z-99" v-if="selectedRows.length > 0">
				<div class="container-fluid d-flex justify-content-between align-items-center h70px">
					<span class="d-none d-md-flex text-gray-900 wsnw">
						{{ selectedRows.length }} {{ selectedRows.length == 1 ? 'Order' : 'Orders' }} selected
					</span>
					<div class="ml-auto hidden-sm-down">
						<b-button @click="clearSelected" class="mr-8 mr-md-16">
							Clear
						</b-button>

             <b-button class="errorbtn mr-8 mr-md-16" @click="openbulkCancel" v-if="activeTab == 0">
              Cancel Orders
            </b-button>

						<b-button class="mr-8 mr-md-16"   @click="openEditModal" v-if="activeTab == 0">
              <i class="icon-edit fs16 pr-10"></i>
							Edit Orders
						</b-button>

            <b-button :disabled="loader.booking" @click="bookFromTable('')" v-if="activeTab == 0" >
              Book <b-spinner v-if="loader.booking" class="sm"></b-spinner>
            </b-button>


            <b-button class="errorbtn mr-8 mr-md-16" @click="bulkCancelShipmentsSelected" v-if="activeTab == 4 ||activeTab == 1 || activeTab == 2 || activeTab == 3">
              Cancel Shipments
            </b-button>

            <b-dropdown v-if="activeTab == 2 && bulkLabelAllowedSellers.includes(user.id)" right :disabled="loader.printing" id="dropdown-1" text="Print Label" class="m-md-2 float-right">
              <b-dropdown-item @click="bulkSlip(2)">Download Label (upto 100)</b-dropdown-item>
              <b-dropdown-item @click="bulkSlip(1)">Email Bulk All Booked (Single PDF)</b-dropdown-item>
              <b-dropdown-item @click="bulkSlip(0)">Email Bulk All Booked (Multi PDF)</b-dropdown-item>
            </b-dropdown>

            <b-button v-else-if="activeTab == 2 || activeTab == 4 || activeTab == 1 || activeTab == 3" :disabled="loader.printing" class="mr-8 mr-md-16" variant="secondary" @click="bulkSlip">
							<i class="icon-download mr-10 fs16"></i>
							Print Label <b-spinner v-if="loader.printing" class="sm"></b-spinner>
						</b-button>

						<b-button variant="secondary" @click="rtoReceived" v-if="activeTab == 9" >
							RTO-Received
						</b-button>
					</div>

					<div class="visible-sm d-flex">
						<b-button @click="openEditModal" size="xs" class="mr-16 fs14 fw400 text-gray-900 h40px">
							<i class="icon-edit text-blue fs16 mr-6"></i>
							Edit
						</b-button>
						<b-button @click="openCancelModal" size="xs" class="mr-16 fs14 fw400 text-gray-900 h40px">
							<i class="icon-cancel text-blue fs16 mr-6"></i>
							Cancel
						</b-button>
						<b-button @click="downloadToast" variant="secondary" size="xs" class="fw400 fs14 text-gray-900 h40px">
							<i class="icon-download text-blue fs16 mr-6"></i>
							Download
						</b-button>
					</div>
				</div>
			</div>
		</transition>

		<transition>
			<div class="select-bar d-flex shipments" v-if="filterResultsToggle && selectedRows.length == 0">
				<div class="container-fluid d-flex align-items-center">
					<div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0">
						<div class="d-flex align-items-center">
							<span class="d-none d-md-flex mr-6 text-gray-600 wsnw" v-if="this.selFilters.length != '0'">
								Results for:
							</span>
							<ul class="d-none d-md-flex result-list">
								<li v-for="(selFilter, index) in selFilters" :key="index">
									<b-button class="mx-4 my-1" v-if="!(selFilter=='AWB' || selFilter=='OrderID' || selFilter=='ShipmentID' || index=='filtering')" variant="tag">
										<span class="wsnw text-truncate">
											{{ selFilter == 'true' ? 'Sort By OrderID' : (selFilter.includes('\n') || selFilter.includes(',')) ? 'Multiple': selFilter }}
										</span>
										<i class="icon-close fs6" @click="removeFilter(index)" ></i>
									</b-button>
								</li>
								<li>
									<b-button class="mx-4 my-1" variant="tag">
										<span class="wsnw">
											{{ filters[activeTab].title }}
										</span>
									</b-button>
								</li>
							</ul>

							<span class="d-flex d-md-none" v-if="this.selFilters.length != '0'">
								{{ this.selFilters.length }} Filters selected.
							</span>
							<span class="d-flex d-md-none" v-else>
								No filters selected.
							</span>
							<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
								<span class="text-gray-900 wsnw">Clear filters</span>
								<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
							</b-link>
						</div>

						<div class="d-flex align-items-center">
							<b-button v-b-tooltip.hover.left :title="activeTab != 0 ? 'Shipments Export will be sent to your Registered and Operation Email' : '' " :disabled="loader.exporting" variant="secondary" v-if="(selFilters.dateEnd && daysFromDates()<=30 || selFilters.multiKey && selFilters.searchValue) && items.length>0 " class="wsnw" @click="shipmentExportButton">
								<i class="icon-download fs16"></i>
								<span class="d-none d-md-inline ml-0 ml-md-10">
									Export <b-spinner v-if="loader.exporting" class="sm"></b-spinner>
								</span>
							</b-button>
							<b-button variant="secondary" class="d-none text-gray-900 d-md-flex ml-8" v-b-toggle.sidebarFilters>
								Filters
								<i class="icon-filter text-gray-600 pl-10"></i>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</transition>
<!-- {{items}} -->
<div v-if="this.windowWidth > 767">
  <b-table
  ref="selectableTable"
  :busy="isBusy"
  :items="items"
  :fields="computedFileds"
  :tbody-tr-class="tbodyRowClass"
  :per-page="perPage"
  sticky-header
  no-border-collapse
  @row-clicked="tbodyRowAttr"
  class="shipments-table table-sm-cards"
  >

  <!-- Table Busy loader -->
  <template #table-busy>
    <div class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </template>

  <!-- A custom formatted header cell for field 'selected' -->
  <template v-slot:head(selected)>
    <div class="d-flex justify-content-center align-items-center">
      <b-checkbox
      v-model="allSelected"
      :indeterminate="allSelected && selectedRows.length!=items.length"
      @change="toggleAllRows"
      ></b-checkbox>
    </div>
  </template>

  <!-- Example scoped slot for select state illustrative purposes -->
  <template v-slot:cell(selected)="{ item, field: { key } }">
    <div class="d-flex justify-content-center w-sm-50 align-items-center">
      <b-checkbox @input="orderPersist" v-if="activeTab == 0" :disabled="item.status != 0 || item.valid == false || item.channelID == 9 || item.hasError" v-model="item[key]" :ref="item.id"></b-checkbox>
      <b-checkbox @input="shipmentPersist" v-if="activeTab != 0 && activeTab != 9" :disabled="item.status != 0 || item.isCancelled != 0 || item.awb == 'N/A'" v-model="item[key]"></b-checkbox>
      <b-checkbox v-if="activeTab == 9" :disabled="item.status != 7 || item.isCancelled != 0 || item.awb == 'N/A'" v-model="item[key]"></b-checkbox>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'Order Id' -->
  <template v-slot:cell(orderId)="data">
    <div class="d-flex flex-column">
      <b-link class="secondary order" v-if="activeTab == 0" :to="'/orders/'+data.item.id">
        <span>
          {{ data.item.importedID }}
        </span>
      </b-link>
      <b-link class="secondary order" v-else :to="'/shipments/'+data.item.id">
        <span>
          {{ data.item.importedID }}
        </span>
      </b-link>
      <span class="fs12 lh16 text-gray-600 text-capitalize">
        {{ data.item.channelName == 'MPS' ? 'MPS' : data.item.channelName == 'API' ? 'API' : String(data.item.channelName).toLowerCase() }}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'Date' -->
  <template v-slot:cell(date)="data">
    <span class="w74 d-block">
      {{ data.item.date | date }}
    </span>
  </template>


  <template v-slot:cell(id)="data">
    <div class="d-flex flex-column w72">
      <span v-if="activeTab == 0">
        {{ data.item.shipmentID }}
      </span>
      <span v-else>
        {{ data.item.id }}
      </span>
      <span class="fs12 lh16 text-gray-600" v-if="activeTab != 0">
        Est. ₹ {{ data.item.estimatedValue ? data.item.estimatedValue.toFixed(2) :'0.00' }}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'Customer' -->
  <template v-slot:cell(customer)="data">
    <div class="d-flex flex-column wsnw w130px">
      <span class="text-truncate">
        {{ data.item.customerName }} <img v-b-tooltip.hover.right title="Short Customer Address" v-if="data.item.shortAddress" src="@/assets/images/caution.png" class=" warning2" alt="">
      </span>
      <span class="fs12 lh16 text-gray-600 text-truncate" :title="data.item.customerMeta">
        {{ellipsize(data.item.city) }} - {{ data.item.pincode}}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'Value' -->
  <template v-slot:cell(value)="data">
    <div>
      <span class="d-block wsnw" v-if="data.item.channelID == 12">
        $
        {{ data.item.value.toFixed(2) }}
      </span>
        <span class="d-block wsnw" v-else>
        ₹
        {{ data.item.value.toFixed(2) }}
      </span>
      <span class="value-type" :class="data.item.type.toLowerCase()">
        {{ data.item.type }}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'Warehouse' -->
  <template v-slot:cell(warehouse)="data">
    <div class="d-flex flex-column w130px">
      <span class="text-truncate">
        {{ data.item.warehouse }}
      </span>
      <span class="fs12 lh16 text-gray-600">
        {{ data.item.warehouseCity }} - {{ data.item.warehousePostcode }}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'SKUs' -->
  <template v-slot:cell(SKUs)="data" v-if="activeTab == 0">
    <div class="text-right">
      <span class="wsnw">
        {{ data.item.skuCount }}        
        <i
            class="icon-disclaimer fs12 text-gray-400"
            :id="`${data.item.id}`"
          ></i>
        <b-popover
          :target="`${data.item.id}`"
          placement="bottom"
          title="Popover!"
          triggers="hover focus"
          custom-class="mw373 position-absolute z-99"
          style="z-index: 999;position: absolute;"
        >
          <span v-for="(item, index) in data.item.itemPopover">
            <div class="text-gray-600" style="min-width: 200px;">
              <hr class="my-10" v-if="index > 0">
              <div>
                <span>Item Name </span>
                <span class="float-right">{{item.itemQuantity}} Qty.</span>
              </div>
              <span class="font-weight-normal">{{item.itemName}}</span>
            </div>
          </span>
        </b-popover>
      </span>

      <span class="d-flex flex-column fs12 lh16 text-gray-600">
          {{ data.item.quantity }} Qty.
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'Dimensions' -->
  <template v-slot:cell(dimensions)="data">
    <div class="d-flex flex-column">
      <span>{{ parseFloat(data.item.weight != null ? data.item.weight : 0).toFixed(2) }} kg</span>
      <span class="fs12 lh16 text-gray-800">
        {{ data.item.multiple ? 'Multiple' : parseInt(data.item.length != null ? data.item.length : 0) + 'x' + parseInt(data.item.width != null ? data.item.width : 0) + 'x' + parseInt(data.item.height != null ? data.item.height : 0)
        }}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'DeliveryMode' -->
  <template v-slot:cell(mode)="data">
    <div class="d-flex flex-column">
      <span class="wsnw">{{ data.item.modeValue }}</span>
      <span class="fs12 lh16 carrier font-weight-bold" :data-carrier="data.item.carrierID">
        {{ data.item.carrier }}
      </span>
    </div>
  </template>

  <!-- A custom formatted data column cell for field 'AWB' -->
  <template  v-slot:cell(awb)="data">
    <b-link
    v-if="data.item.awb !='N/A'"
    @click="copySuccess"
    v-clipboard:copy="data.item.awb"
    v-b-tooltip.hover.right
    class="cp underline-dashed text-gray-800 text-decoration-none"
    title="Click to copy!"
    >
    {{ data.item.awb }}
  </b-link>
  <span v-else class="text-gray-800">N/A</span>
</template>

<!-- A custom formatted data column cell for field 'Status' -->
<template v-slot:cell(status)="data">
  <div class="d-flex align-items-center" :class="{'mr-12': activeTab == 0 }">
    <div :class="`${activeTab == 0 ? 'w80px' : 'w120px'}`">
      <template v-if="data.item.button && activeTab == 0 && data.item.finalStatus == 'Book' && !data.item.hasError">
        <b-button :class="{'bg-white text-blue shadow': data.item.hasDimension == false }" variant="primary btn-xs fw700 w85px" :disabled="disableSinglebook || data.item.buttonDisabled" @click="orderBookFromRow(data.item)">
            Book
        </b-button>
      </template>

      <template v-if="activeTab == 0 && data.item.hasError">
        <div class="d-flex" style="width:7.5rem">
            <b-button variant="primary btn-xs fw700 w85px" @click="$router.push({path: 'orders/' + data.item.id, query: {'fullEdit' : true}})">
              Edit
          </b-button>
          <img :id="`${data.item.importedID}`" src="@/assets/images/warning1.png" class="w-25 warning1" alt="">
          <b-popover
          :target="`${data.item.importedID}`"
          placement="righttop"
          title="Popover!"
          triggers="hover focus"
          custom-class="mw373"
        >
        <ol v-if="data.item.errors.length > 1">
          <li class="text-gray-600" v-for="(item, index) in data.item.errors">
            {{item}}
          </li>
        </ol>
        <span v-else class="text-gray-600" v-for="(item, index) in data.item.errors"> {{item}}</span>
        
        </b-popover>
        </div>
        
      </template>

      <template v-if="activeTab == 0 && data.item.finalStatus != 'Book' && !data.item.hasError">
        <span class="tag" :class="data.item.finalStatus | lowercase">
          {{ data.item.finalStatus }}
        </span>
      </template>

      <template v-if="activeTab != 0">
        <span class="tag mr-10" :class="data.item.finalShipmentStatus | lowercase">
          {{data.item.finalShipmentStatus}}
        </span>
      </template>

      <template v-if="data.item.status == 0 && data.item.isCancelled == 0 && data.item.awb != 'N/A' && activeTab != 0 && activeTab != 9 && data.item.type != 'reverse'">
        <b-button class="mr-10" variant="primary btn-xs"  @click="singleSlipDownload(data.item.id)">
          <i class="icon-download fs12"></i>
        </b-button>
      </template>
    </div>

      <template v-if="activeTab == 0  && !data.item.hasError">
        <b-link @click="toggleDetails(data.item)" :disabled="data.item.hidequickedit || (data.item.button && data.item.finalStatus == 'Book' ? false : true)">
          <i class="icon-edit-quick fs20 ml-20 p-0 text-blue" :class="{'text-gray-400': data.item.hidequickedit || (data.item.button && data.item.finalStatus == 'Book' ? false : true) }"></i>
        </b-link>
      </template>

      <ShipmentsTableDropdown 
          ref="ShipmentsTableDropdown"
          v-if="activeTab == 0 && data.item.status != 9 && data.item.status != 2 && data.item.status != 8"
          @toggleCancel="openCancelModal(data.item.id)"
          :orderID = "data.item.id"
          :hidefullEdit = "data.item.hidequickedit"
          class="d-inline-block"
          :class="{'ml-40': data.item.hasError}"
      />
        <!-- @toggleDetailsRow="toggleDetails(data.item)" -->
    </div>
    </template>
    
    <template v-slot:row-details="row">
        <b-form @submit.prevent="formSubmit(row.item)">
            <div class="expanded-row">

                <span class="fs12 lh14 ml-10 mr-8">MODE</span>
                <multiselect
                    id="mode"
                    class="select-xs w120px"
                    v-model="row.item.mode"
                    :options="row.item.modeList"
                    title="Mode Type"
                    label="name"
                    :searchable="false"
                    :show-labels="false"
                    required
                ></multiselect>

                <span class="fs12 lh14 ml-14 mr-8">LENGTH (CM)</span>
                <b-form-input
                    v-model="row.item.length"
                    class="small w50px"
                    placeholder="0"
                    type="number"
                    min="1"
                    max="999"
                    required
                ></b-form-input>

                <span class="fs12 lh14 ml-14 mr-8">WIDTH (CM)</span>
                <b-form-input
                    v-model="row.item.width"
                    class="small w50px"
                    placeholder="0"
                    type="number"
                    min="1"
                    max="999"
                    required
                ></b-form-input>

                <span class="fs12 lh14 ml-14 mr-8">HEIGHT (CM)</span>
                <b-form-input
                    v-model="row.item.height"
                    class="small w50px"
                    placeholder="0"
                    type="number"
                    min="1"
                    max="999"
                    required
                ></b-form-input>

                <span class="fs12 lh14 ml-14 mr-8">WEIGHT (KG)</span>
                <b-form-input
                    v-model="row.item.weight"
                    class="small w50px"
                    placeholder="0.00"
                    type="number"
                    step="0.01"
                    required
                    :min="$store.state.minW"
                    :max="$store.state.maxW"
                ></b-form-input>

                <span class="fs12 lh14 ml-14 mr-8">WAREHOUSE</span>
                <multiselect
                    id="mode"
                    class="select-xs mw170"
                    v-model="row.item.warehouseName"
                    :options="warehousesList"
                    label="name"
                    required
                    :searchable="false"
                    :show-labels="false"
                ></multiselect>

                <div class="ml-auto">
                    <b-button
                        variant="tertiary"
                        size="xs"
                        class="px-24 mr-12 quickedit-book"
                        type="submit"
                        >
                        Save & Book
                    </b-button>

                    <b-button
                        @click="row.toggleDetails"
                        variant="icon-transparent text-blue"
                        class="px-8"
                        >
                        <i class="icon-close fs10"></i>
                    </b-button>
                </div>

            </div>
        </b-form>
    </template>

</b-table>
</div>

        <ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
          <li class="d-flex flex-column" v-for="items in items" :key="items.id">
            <div class="d-flex justify-content-between">
              <span class="d-block fw500 mb-4 cp" @click="navigate(items.id)">
                <strong>Order ID: </strong> {{items.importedID}}
              </span>

              <div v-if="activeTab != 0">
                <span class="tag mr-10" :class="items.finalShipmentStatus | lowercase">
                  {{items.finalShipmentStatus}}
                </span>
              </div>
            </div>

            <span class="mb-4">
              <strong>Date: </strong> {{ items.date | date}}
            </span>

            <span class="carrier-mode mb-4">
              <strong>Mode: </strong> {{ items.modeValue }}
            </span>

            <span class="carrier-name mb-4">
              <strong>Carrier:</strong> {{ items.carrier }}
            </span>

            <span v-if="items.awb && activeTab != 0" class="mb-4">
                <strong>AWB: </strong> {{ items.awb }}
            </span>

            <div class="d-flex justify-content-between">
              <div>
                <span class="d-block wsnw mb-4" v-if="items.channelID == 12">
                 ${{ items.value.toFixed(2) }}
                </span>
                  <span class="d-block wsnw mb-4" v-else>
                  ₹{{ items.value.toFixed(2) }}
                </span>
                <span class="value-type" :class="items.type.toLowerCase()">
                  {{ items.type }}
                </span>
              </div>

              <div>
                <div v-if="items.button && activeTab == 0 && items.finalStatus == 'Book'">
                  <b-button :class="{'bg-white text-blue shadow': items.hasDimension == false }" variant="primary btn-xs fw700 w85px" :disabled="disableSinglebook || items.buttonDisabled" @click="orderBookFromRow(items)">
                      Book
                  </b-button>
                </div>

                <div v-if="activeTab == 0 && items.finalStatus != 'Book'">
                  <span class="tag" :class="items.finalStatus | lowercase">
                    {{ items.finalStatus }}
                  </span>
                </div>

                <div v-if="items.status == 0 && items.isCancelled == 0 && items.awb != 'N/A' && activeTab != 0 && activeTab != 9 && items.type != 'reverse'">
                  <b-button class="mr-10" variant="primary btn-xs"  @click="singleSlipDownload(items.id)">
                    <i class="icon-download fs12"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div v-if="!isBusy" class="pagination-bar">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <div class="d-inline-flex wsnw align-items-center">
                  <div class="d-block w50px">
                    <multiselect
                    class="pagination-items__pp"
                    v-model="perPage"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                    :options="pageOptions"
                    @input="setPageNumber"
                    ></multiselect>
                  </div>
                  <span
                  class="pl-8 fs12 lh24 text-gray-600 pagination-items"
                  ></span>
                </div>
              </div>
              <div class="col-8 text-right">
                <div class="d-inline-flex ml-auto wsnw align-items-center">
                  <p class="fs12 lh24 text-gray-600 pr-14">
                    Page: {{ currentPage }}
                  </p>
                <b-button-group>
                  <b-button
                  @click="prevPage()"
                  :disabled="prevDisabled"
                  variant="paginate left"
                  >
                  <i class="icon-back fs12"></i>
                </b-button>

                <b-button
                @click="nextPage()"
                :disabled="nextDisabled"
                variant="paginate right"
                >
                <i class="icon-arrow fs12"></i>
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>

    <EditOrderModal :editOrderID.sync="editOrderID" @bookFromTable="bookFromTable" :selectedImportedID.sync="selectedImportedID" :skuOrderID="skuOrderID" :selectedEditOrder.sync="selectedEditOrder" :orderIDs="orderIDs" ref="editOrderModal" :saveSKUInTable.sync="saveSKUInTable"/>

    <choiceDialog @openModal="openPricingModal" :bookOrderFlag="bookOrderFlag" :formInfo="formInfo" :choiceData="choiceData" ref="choiceDialog" />

    <Bulkbooking :bulkbookingArr="bulkbookingArr" ref="bulkbooking"></Bulkbooking>

    <ShipmentsCancelModal ref="cancelOrderModal" :orderID="cancelOrderID" />
    <BulkCancel :selectedCancelOrders="selectedCancelOrders" ref="bulkCancel"  />
    <bulkCancelShipments :selectedShipments="selectedShipments" ref="bulkCancelShipments"  />

  <ResultNotFound v-if="items.length == 0 && !isBusy" />
</div>
</template>

<script>
  import Multiselect from "vue-multiselect";
  import vClickOutside from "v-click-outside";
  import { createPopper } from "@popperjs/core";
  import { mapState } from 'vuex'
  import orderSideFilter from "@/components/orderSideFilter.vue";
  import shipmentFilterSidebar from "@/components/shipmentFilterSidebar.vue";
  import EditOrderModal from "@/components/EditOrderModal.vue";
  import ResultNotFound from "@/components/ResultNotFound.vue";
  import ShipmentsCancelModal from "@/components/ShipmentsCancelModal.vue";
  import ShipmentsTableDropdown from "@/components/ShipmentsTableDropdown.vue";
  import choiceDialog from "@/components/choiceDialog.vue";
  import BulkCancel from "@/components/BulkCancel.vue";
  import bulkCancelShipments from "@/components/bulkCancelShipments.vue";
  import Bulkbooking from "@/components/Bulkbooking.vue";
  

  export default {
    name: "ShipmentsTable",
    props: ['warehouses'],
    data() {
      return {
        hiddencheck:false,
        loader: {
          exporting: false,
          printing: false,
          booking: false
        },
        pending: true,
        selectedShipments : [],
        selectedCancelOrders :[],
        fields: [
        {
          key: "selected",
        },
        {
          key: "orderId", label:"Order ID",
        },
        {
          key: "date",
        },
        {
          key: "id", label: "Shipment ID"
        },
        {
          key: "customer",
        },
        {
          key: "value",
        },
        {
          key: "warehouse",
        },
        {
          key: "SKUs",
          thClass: "text-right pr-4",
          tdClass: "pr-0",
        },
        {
          key: "dimensions",
        },
        {
          key: "mode",
        },
        {
          key: "awb", label: "AWB"
        },
        {
          key: "editActive",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "status",
          thStyle: { width: "100px !important", "padding-left": "18px" },
          tdClass: "pr-0 tdstatus",
        },
        ],
        filters: [
        {
          id: 0,
          title: "Unprocessed", label: "Unprocessed",
        },
        {
          id: 1,
          title: "All",
        },
        {
          id: 2,
          title: "Booked",
        },
        {
          id: 3,
          title: "Printed",
        },
        {
          id: 4,
          title: "Manifested",
        },
        {
          id: 5,
          title: "In Transit",
        },
        {
          id: 6,
          title: "Exception",
        },
        {
          id: 7,
          title: "OFD",
        },
        {
          id: 8,
          title: "Delivered",
        },
        {
          id: 9,
          title: "RTO/REV",
        },
        {
          id: 10,
          title: "RTO/REV-Received",
        },
        {
          id: 11,
          title: "Cancelled",
        },
        ],
        orderMenuToggle: true,
        pageOptions: this.$store.state.pageOptions,
        totalRows: 1,
        currentPage: parseInt(this.$route.query.page) || 1,
        tableOrderID: '',
        perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
        totalPages: 1,
        prevDisabled: true,
        nextDisabled: false,
        buttonClicked: false,
        startPage: 1,
        placement: "top",
        pagesArr: [],
        selectedImportedID: '',
        items: [],
        allSelected: false,
        selectedOrders: null,
        selFilters: this.$store.getters.filters.shipments || {},
        filterResultsToggle: this.$store.getters.filters.shipments ? true : false,
        editModal: false,
        isBusy: true,
        shipmentsFinalData: '',
        isEnabled: false,
        ids: [],
        warehouseID: '',
        oldShipmentsData: [],
        cancelOrderID: '',
        saveSKUInTable: false,
        modeType: '',
        orderID: '',
        formInfo: [],
        choiceData: [],
        bookOrderFlag: "yes",
        selectedEditOrder: [],
        editOrderID: '',
        skuOrderID: [],
        quickEdit: {},
        checkedOrder:this.$store.getters.global.checkedOrderlist || [],
        exportData: "",
        activeTab: parseInt(this.$route.query.tab) || 0,
        selectedFilter: parseInt(this.$route.query.tab) || 0,
        orderIDs: [],
        status: ['pending', 'all', 'booked', 'printed', 'manifested', 'transit', 'exception', 'delivery', 'delivered', 'rto', 'rtoreceived', 'cancelled'],
        disableSinglebook:false,
        orderFromRowBook:false,
        bulkbookingArr:[],
        bulkLabelAllowedSellers: []
      };
    },
    components: {
      Bulkbooking,
      Multiselect,
      orderSideFilter,
      shipmentFilterSidebar,
      EditOrderModal,
      ResultNotFound,
      ShipmentsCancelModal,
      ShipmentsTableDropdown,
      choiceDialog,
      BulkCancel,
      bulkCancelShipments
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    mounted() {

      // Hardcoded condtion for sellerID: 53772
      if([165,66510,53772,72511,39716,165799,7902,192618].includes(this.user.id)) {
        this.pageOptions = [...this.pageOptions, ...[200, 300, 400, 500]]
      }

      this.totalRows = this.items.length;
      this.totalPages = Math.ceil(this.totalRows / this.perPage);

      for (var i = 1; i <= this.totalPages; i++) {
        this.pagesArr.push(i);
      }

      this.$root.$on("eventing", () => {
        this.openCancelModal;
      });

      if(this.$route.query.manifestID) {
        this.pageTab(1)
        this.filterResultsToggle = true
        this.$refs.filter.addFilter(this.$route.query)
        this.$refs.filter.form = this.$route.query
        this.$refs.filter.applyFilter()
        this.selFilters = this.$route.query
        this.$router.replace('')
        this.shipmentPageData()
      }
      if(this.activeTab == 0) {
        this.orderPageData()
      }else{
        this.shipmentPageData();
      }
      
    },
    updated() {
    },
    methods: {                                
      orderPersist() {
        const prows = this.selectedRows.map(i => i.id)
        setTimeout(() => {
          this.$store.commit('setGlobal', {'prows':prows})
        })
      },
      shipmentPersist() {
        const ships = this.selectedRows.map(i => i.id)
        setTimeout(() => {
          this.$store.commit('setGlobal', {'ships':ships})
        })
      },
      pageTab(id) {
        this.activeTab = id
        this.selectedFilter = id
        this.currentPage = 1
        this.perPage = 25
        if(id == 0) {
          this.orderPageData()
        }else{
          this.shipmentPageData();
        }

      },

        openPricingModal: function() {
          this.$refs.choiceDialog.isOpen = !this.$refs
            .choiceDialog.isOpen;
        },

        async orderPageData(params) {

        	try {

        		this.isBusy = true;

        		if(!params) {
        			let params = {}
        		}
        		if(Object.keys(this.selFilters).length > 0) {
        			params = {...{filtering: true}, ...this.selFilters}
        		}

            if(params && params.warehouseID) {
              params.warehouseID = this.$store.getters.warehouses.find(i => i.name == params.warehouseID).id
            }

            if(params && params.storeName) {
              params['channelKeyID'] = this.$store.state.global.storenames.find(i => i.storeName == params.storeName).id
              delete params.storeName;
            }

            this.exportData = params

        		const result = await this.axios.get(`/orders?page=${this.currentPage}&offset=${this.perPage}`, {params:params});

        		this.items = result.data.updateOrderResponse.map(i => {
              let modes = undefined;
              // MPS
              if (i.channelID == 9) {
                modes = this.modes.mps;
              }
              // International
              else if (i.channelID == 12) {
                modes = this.modes.intl;
              }
              // B2B
              else if (i.channelID == 37) {
                modes = this.modes.b2b;
              }
              else {
                modes = this.modes.domestic;
              }
              const prows = this.$store.getters.global.prows || []
              if(prows.indexOf(i.id)>-1) {
                i.selected = true
              }
              i.modeList = modes;
              if (i.hasOwnProperty('errors')) {
                i['hasError'] = true
              } else {
                i['hasError'] = false
              }
              return i;
            });
            this.totalRows = this.items.length;
            this.pagination = result.data.pagination;
            this.setPagination()
            if(this.items.length < this.perPage) {
              this.nextDisabled = true
            }
        	}
        	catch(error){
        		console.error(error);
        	}
        	this.isBusy = false
        },

        async shipmentPageData(params) {

        	try {

        		this.isBusy = true;

        		const status = this.status[this.activeTab];

        		if(!params) {
        			let params = {}
        		}
        		if(Object.keys(this.selFilters).length > 0) {
        			params = {...{filtering: true}, ...this.selFilters}
              if(params.multiKey) {
                params.multiKey = params.multiKey=='OrderID'?1:params.multiKey=='AWB'?2:3
              }
        		}
            if(params && params.warehouseID) {
              params.warehouseID = this.$store.getters.warehouses.find(i => i.name == params.warehouseID).id
            }
            if(params && params.channel) {
              params.channel = this.$store.getters.channels.find(i => i.name == params.channel).value
            }

            // Remove whitespace from multiple IDs
            if(params && params.searchValue) { 
              // params.searchValue = params.searchValue.replace(/\s+|\s+/g,'')

              params.searchValue = params.searchValue.replace(' ,',',')
              params.searchValue = params.searchValue.replace('  ,',',')
              params.searchValue = params.searchValue.replace(', ',',')
              params.searchValue = params.searchValue.replace(',  ',',')
            }

            this.exportData = params

        		const result = await this.axios.get(`/shipments/${status}?page=${this.currentPage}&offset=${this.perPage}`, {params:params});

        		this.items = result.data.finalShipments.map(i => {
              const ships = this.$store.getters.global.ships || []
              if(ships.indexOf(i.id)>-1) {
                i.selected = true
              }
              return i
            })
            this.bulkLabelAllowedSellers = result.data.bulkLabelAllowedSellers
        		this.totalRows = this.items.length;
        		this.pagination = result.data.pagination;
        		this.setPagination()

        		if(this.items.length < this.perPage) {
        			this.nextDisabled = true
        		}
        	}
        	catch(error){
        		this.nextDisabled = true
        		this.popToast('failed', 'Failed!', error)
        	}
        	this.isBusy = false;

        },

        async singleSlipDownload(value) {

          this.popToast("requested", "Download requested", "Your download should start now.");

          let response = await this.axios.get(`/slip/${value}`);

          this.download(response.data.path, response.data.name)
        },

        daysFromDates() {
          const date1 = new Date(this.selFilters.dateStart);
          const date2 = new Date(this.selFilters.dateEnd);
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          return diffDays
        },

        async bulkSlip(type) {
            this.loader.printing = true
            try {

              let orders = this.selectedRows.map(val => { return val.id });
  
                if(orders.length < 101) {
                  this.popToast("requested", "Download requested", "Your download should start now.");
                }

              let result

              if(type == 0 || type == 1) {
                result = await this.axios.post('/v4/bulkslip', { 
                  // shipments: orders,
                  isSingle: type
                })
              } else {
                result = await this.axios.post('/bulkslip', {
                  shipments: orders
                })
              }

              if (result.data.s3Path) {
                window.open(result.data.s3Path,'_new');
                // this.download(result.data.s3Path, result.data.fileName)
              }

              if(result.data.error){
                this.popToast(
                  "failed",
                  "Booking disabled!",
                  result.data.error.includes("notFound") ? "Slip not found" : result.data.error
                );
              }

              if (result.data.message) {
                this.popToast(
                  result.data.status ? "booked" : "failed",
                  "Success",
                  result.data.message
                );
              }
            } catch (err) {
              console.error(err)
            }
            this.loader.printing = false


        },
        async formSubmit(row) {

            if (row.warehouseName && row.warehouseName.id == '' || row.mode == null) {
              this.popToast("failed", "Failed", "All field are mandatory!");
              return false;
            }

            this.quickEdit.itemHeight = row.height
            this.quickEdit.itemLength = row.length
            this.quickEdit.itemWeight = row.weight
            this.quickEdit.itemWidth = row.width
            this.quickEdit.modeType = row.mode.value
            this.quickEdit.warehouseID = row.warehouseName?row.warehouseName.id:null
            this.quickEdit.orderID = [row.id]


            let itemNotZero = false;
            let itemZero = false;

            Object.keys(this.quickEdit).forEach((element, index) => {

                if (element.includes('item')) {

                    if (this.quickEdit[element] <= 0 || String(this.quickEdit[element]).trim() == '') {

                        itemZero = true;
                    }
                    else {

                        itemNotZero = true;
                    }
                }
            });

            if (itemNotZero && itemZero) {
                this.popToast("failed", "Failed", "Please Fill Dimensions Details");
                return;
            }

            let response = await this.axios.post('/order/quickedit', this.quickEdit);

            if (response.data.success == false) {
                this.popToast("failed", "Failed", response.data.error);
            }

            if (response.data.success == true) {
                this.popToast("booked", "Success", response.data.message);
                this.orderBookFromSaveBook(row); 
            }
        },
        toggleOrderMenu() {
          this.orderMenuToggle = !this.orderMenuToggle;
        },
        setPagination() {
          this.nextDisabled = this.pagination.next ? false : true
          this.prevDisabled = this.pagination.prev ? false : true
          this.$router.push(`?tab=${this.activeTab}&page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
        },
        pushTotalPages() {

          this.pagesArr = [];

          for (var i = 1; i <= this.totalPages; i++) {
            this.pagesArr.push(i);
          }
        },
        setPageNumber() {
          this.currentPage = 1
          if(this.activeTab == 0) {
            this.orderPageData()
          }else{
            this.shipmentPageData();
          }

          this.totalPages = Math.ceil(this.totalRows / this.perPage);
          this.pagesArr = [];

          for (var i = 1; i <= this.totalPages; i++) {
            this.pagesArr.push(i);
          }

          this.prevDisabled = true;

          if (this.totalPages == 1) {
            this.nextDisabled = true;
          } 
          else if (this.totalPages > 1) {
            this.nextDisabled = false;
          }
        },

        updatePageNumber() {

            this.totalPages = Math.ceil(this.totalRows / this.perPage);
            this.pagesArr = [];

            for (var i = 1; i <= this.totalPages; i++) {
                this.pagesArr.push(i);
            }
        },
        async bookFromTable(selectedOrderID) {
            this.loader.booking = true

            let editabledOrderIds = this.selectedRows.filter(i => (!i.mode || i.mode == 'N/A' || (i.hasDimension == false && i.quantity >= 1) || (i.hasDimension == false && i.skuCount >= 1)) ? true : false).map(i => i.id)

            if (editabledOrderIds.length >= 1) {
              this.editOrderID = editabledOrderIds
              this.openEditModal()

            } else {
              let orders = selectedOrderID != '' ? selectedOrderID : this.selectedRows.map(val => { return val.id });

              if (this.$store.state.user.prepaid == 1 && orders.length && (orders.length * 50 > this.$store.state.global.overview.due)) {
                this.loader.booking = false
                this.bulkbookingArr = [...orders]
                this.$refs.bulkbooking.isOpen = !this.$refs.bulkbooking.isOpen
                return false;
              }

              let result = await this.axios.post('/bulkorder', {
                  orders: orders
              })

              if (result.data && result.data.success == true) {
                  this.popToast("booked", "Order booked", result.data.message);
                  this.orderPageData();
              }
              else {
                  this.popToast("failed", "Booking error!", result.data.message);
                  if(!(result.data.message.includes('No service found on this pincode') || result.data.message.includes('Your booking is disabled. Please check your account balance.'))) {
                    this.openEditModal()
                    this.selectedEditOrder = this.selectedRows.map(val => { return val.id });
                  } 
              }

              // if (result.data.hasOwnProperty("skuIDs") ) {
              //     this.skuOrderID = result.data.orderID.length == 0 ? this.selectedRows.id : result.data.orderID
              //     this.openEditModal()
              // }

            }

            
            this.clearSelected();
            this.loader.booking = false
        },

        async rtoReceived() {

            let orders = this.selectedRows.map(val => { return val.id });

            let result = await this.axios.post('/rtoreceived', {
                shipments: orders
            })

            if (result.data && result.data.success == true) {
                this.popToast("booked", "Booked", result.data.message);

                setTimeout(() => { 
                  this.orderPageData();
                }, 1000)
            }
            else {
                this.popToast("failed", "Failed", result.data.message);
            }

            this.clearSelected();

        },

        async orderBookFromSaveBook(item) {

          this.buttonClicked = true;
          let orderID = item.id

          if (this.$store.getters.user.choice == 0) {

            this.disableSinglebook = true

            let result = await this.axios.post('/bulkorder', { orders: [orderID] });

            if (result.data.success == true) {
              this.popToast(
                "booked",
                "Order booked",
                "Order Added Successfully"
                );
              const index = this.items.findIndex(i => i.id == item.id)
              this.items[index].finalStatus = 'Processing'
            }
            else {
              if(result.data.editableOrderIds && result.data.editableOrderIds.length > 0) {
                  this.openEditModal()
                  this.editOrderID = result.data.editableOrderIds
                } else {
                   this.popToast("failed", "Booking disabled!", result.data.message);
                }
            }

            // if(result.data.hasOwnProperty("skuIDs")) {
            //   this.editOrderID = orderID
            //   this.openEditModal()
            // }

            this.disableSinglebook = false
            this.clearSelected();

          }
          else {

            let diamentionsResult = await this.axios.post('/choice/getdiamentions', { orders: [orderID]});

            if (diamentionsResult.data.response.response.weightFlag == false) {
              this.openEditModal()
              this.editOrderID = orderID
              return false;
            }

            if (diamentionsResult.data.response.choice) {

              if(diamentionsResult.data.response.success && diamentionsResult.data.response.response.dimensionError) { 

                try {
                    let response = await this.axios.post('/choice/choiceordersingle', { orders: [orderID] })

                    this.formInfo = response.data.result.orderDetail[0];
                    this.choiceData = response.data.result.response[0];

                    this.openPricingModal();
                }
                catch (excetion) {
                    this.popToast('failed','Failed',excetion.response.data)
                }

              }
              else{
                this.toggleDetails(item)
                // Comment out this line to fix quick edit open when filter applied.
                // this.$refs.ShipmentsTableDropdown.quickEdit()
              }

            }
          }
        },

        async orderBookFromRow(item) {

          this.buttonClicked = true;
          let orderID = item.id

          if (this.$store.getters.user.choice == 0) {

            this.disableSinglebook = true
            
            if(!item.mode || item.modeValue == 'N/A' || (item.hasDimension == false && item.quantity > 1) || (item.hasDimension == false && item.skuCount > 1)) {
              this.orderFromRowBook = true
              this.disableSinglebook = false
              this.editOrderID = orderID
              this.openEditModal()

              if([3,4,5,6,7,10].includes(item.channelID) && item.skuCount == 1 && item.quantity == 1)
                this.saveSKUInTable = true

              return false
            }

            let result = await this.axios.post('/bulkorder', { orders: [orderID] });

            if (result.data.success == true) {
              this.popToast(
                "booked",
                "Order booked",
                "Order Added Successfully"
                );
              const index = this.items.findIndex(i => i.id == item.id)
              this.items[index].finalStatus = 'Processing'
            }
            else {

              if(result.data.editableOrderIds && result.data.editableOrderIds.length > 0) {
                  this.openEditModal()
                  this.editOrderID = result.data.editableOrderIds
              } 
              else {
                   if(result.data.message == 'Please fix SKU in order') {
                    this.openEditModal()
                    this.editOrderID = orderID

                    if([3,4,5,6,7,10].includes(item.channelID))
                      this.saveSKUInTable = true

                    this.disableSinglebook = false
                    return false
                   }
                   else {
                    this.popToast("failed", "Booking disabled!", result.data.message);
                    this.disableSinglebook = false
                   }
              }
            }

            // if(result.data.hasOwnProperty("skuIDs")) {
            //   this.editOrderID = orderID
            //   this.openEditModal()
            // }

            this.disableSinglebook = false
            this.clearSelected();

          }
          else {

            this.disableSinglebook = true

            let diamentionsResult = await this.axios.post('/choice/getdiamentions', { orders: [orderID]});

            if(diamentionsResult.data.response.response.skuDimensionError && (diamentionsResult.data.response.response.itemsWithSku).length == 1 && diamentionsResult.data.response.response.itemsWithSku[0].quantity == 1) {
                this.orderFromRowBook = true
                this.editOrderID = orderID
                this.saveSKUInTable = true
                this.disableSinglebook = false
                this.openEditModal()

            }
            else if (diamentionsResult.data.response.response.weightFlag == false) {
              this.orderFromRowBook = true
              this.editOrderID = orderID
              this.disableSinglebook = false
              this.openEditModal()
              return false;
            }

            else if (diamentionsResult.data.response.choice && diamentionsResult.data.response.success && diamentionsResult.data.response.response.dimensionError) { 
                try {

                  let response = await this.axios.post('/choice/choiceordersingle', { orders: [orderID] })

                  this.formInfo = response.data.result.orderDetail[0];
                  this.choiceData = response.data.result.response[0];
                  this.$store.commit('setGlobal',{bookfromorderTable : true})
                  this.openPricingModal();
                }
                catch (excetion) {
                    this.popToast('failed','Failed',excetion.response.data)
                }

            }
            else {
              this.orderFromRowBook = true
              this.editOrderID = orderID
              this.openEditModal()
            }

            this.disableSinglebook = false
          }
        },

        toggleDetails(item) {

            let warehouse = this.warehousesList.find(i => i.name == item.warehouseName);

            item.warehouseName = warehouse || item.warehouseName
            item.mode = item.modeList.find(i => i.value == item.mode) || item.mode;


          if (item["_showDetails"]) item["_showDetails"] = false;
          else this.$set(item, "_showDetails", true);
        },
        tbodyRowClass(item) {

          return ["b-table-row-selected"];
           

        },
        tbodyRowAttr(record, index, event) {
          
          if(event.srcElement.className == 'pr-0 tdstatus'){
            return false;
          }

          if (!this.buttonClicked) {

            let url = ``;

            if (this.activeTab == 0){
              url = `orders/${record.id}`;
            } else {
              url = `shipments/${record.id}`;
            }

            let newTab = event.metaKey || event.ctrlKey;
            if (newTab) {

              window.open(url);
            }
            else {

              this.$router.push(url)
            }
          }
          else {
            this.buttonClicked = false;
          }

        },
        toggleAllRows() {
          this.ids = []
          if (this.allSelected == true) {
            this.items.forEach((item) => {

              if(item.status == 0 && this.activeTab == 0 && !item.buttonDisabled) {
                this.$set(item, "selected", true);
              }

              if(item.isCancelled == 0 && item.awb != 'N/A' && this.activeTab != 0 && item.status == 0) {
                this.$set(item, "selected", true);
              }

              if(item.channelID == 9 && this.activeTab == 0) {
                this.$set(item, "selected", false);
              }

              this.allSelected = true;
            });

            this.selectedRows.map((i) => {
              this.ids.push(String(i.id));
            })

            this.orderIDs = this.ids;
          } 
          else {
            this.checkedOrder = []
            this.selectedRows.forEach((item) => {

              // this.$delete(item, "selected");
              this.$set(item, "selected", false);

              this.allSelected = false;
            });
          }
          this.$store.commit('setGlobal',{checkedOrderlist : this.checkedOrder})
        },
        clearSelected() {

          this.selectedRows.forEach((item) => {

            // this.$delete(item, "selected");
            this.$set(item, "selected", false);
          });

          this.allSelected = false;
        },
        shipmentExport(value) {
          if(value == true) {
            this.isEnabled = value;
          }
        },

        getFilters() {
        	this.currentPage = 1
        	this.filterResultsToggle = true;
        	this.selFilters = this.$store.getters.filters.shipments
        	this.pageTab(this.activeTab)
        },

        removeFilter(index) {
        	const selFilters = {...this.selFilters}
        	if(index == 'dateEnd' || index == 'dateStart') {
        		delete selFilters.dateEnd
        		delete selFilters.dateStart
        		delete this.$refs.filter.selectedFilters.dateEnd
        		delete this.$refs.filter.selectedFilters.dateStart
        		delete this.$refs.filter.form.date
        	}else if(index == 'multiKey' || index == 'searchValue') {
            delete selFilters.multiKey
            delete selFilters.searchValue
            delete this.$refs.filter.selectedFilters.multiKey
            delete this.$refs.filter.selectedFilters.searchValue
          }else{
        		delete selFilters[index]
        		delete this.$refs.filter.selectedFilters[index]
        	}
        	this.selFilters = selFilters
        	this.$store.commit('setFilter', {'shipments': selFilters})
        	delete this.$refs.filter.form[index]
        	if(Object.keys(this.selFilters).length > 0) {
        		if (this.activeTab == 0) {
        			this.orderPageData();
        		}
        		else {
        			this.shipmentPageData();
        		}
        	}else{
        		this.clearFilters()
        	}
        },
        withPopper(dropdownList, component, { width }) {

          dropdownList.style.width = width;

          const popper = createPopper(component.$refs.toggle, dropdownList, {
            placement: this.placement,
            modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -1],
              },
            },
            {
              name: "toggleClass",
              enabled: true,
              phase: "write",
              fn({ state }) { 
                component.$el.classList.toggle(
                  "drop-up",
                  state.placement === "top"
                  );
              },
            },
            ],
          });

          return () => popper.destroy();
        },
        filterWithAmount({ title, amount }) {
          return `${title} — [ ${amount} ]`;
        },
        copySuccess() {
          this.popToast("booked", "Success", "Successfully copied to clipboard!");
        },
        copyFailed() {
          this.popToast(
            "failed",
            "Copy Failed",
            "We couldn’t copy this url at the moment."
            );
        },
        downloadToast() {
          this.popToast(
            "requested",
            "Download requested",
            "Your download should start now."
            );
        },
        downloadOrders() {
          this.popToast(
            "requested",
            "Download requested",
            "Your download should start now."
            );
        },
        bookedToast() {
          this.popToast(
            "booked",
            "Order Booked",
            "Your order was booked successfully."
            );
        },
        failedToast() {
          this.popToast(
            "failed",
            "Booking Failed",
            "We couldn’t book this order now."
            );
        },
        openCancelModal(value) {

            this.cancelOrderID = value 
            this.$refs.cancelOrderModal.isOpen = !this.$refs.cancelOrderModal.isOpen;
        },
        openEditModal() {
          this.$refs.editOrderModal.isOpen = !this.$refs.editOrderModal.isOpen;
          if(!this.orderFromRowBook) {
            this.selectedEditOrder = this.selectedRows.map(val => { return val.id });
            this.selectedImportedID = this.selectedRows.map(val => { return val.importedID });
          }
        },
        openbulkCancel() {
          this.$refs.bulkCancel.isOpen = !this.$refs.bulkCancel.isOpen;
          this.selectedCancelOrders = this.selectedRows.map(val => { return val.importedID });
        },
        bulkCancelShipmentsSelected () {
          let shipments = this.selectedRows.map(val=> { return val.status == 0 ? val.importedID : ''})
          this.selectedShipments = new Array(...shipments)
          this.$refs.bulkCancelShipments.isOpen = !this.$refs.bulkCancelShipments.isOpen;
        },
        pushTotalPages() {
          this.pagesArr = [];
          for (var i = 1; i <= this.totalPages; i++) {
            this.pagesArr.push(i);
          }
        },
        nextPage() {
          this.currentPage++;
          if (this.activeTab == 0) {
            this.orderPageData();
          }
          else {
            this.shipmentPageData();
          }
          this.clearSelected();
        },
        prevPage() {
          this.currentPage--;

          if (this.activeTab == 0) {
            this.orderPageData();
          }
          else {
            this.shipmentPageData();
          }
          this.clearSelected();
        },
        navigate(id) {
          if(this.activeTab==0) {
            this.$router.push(`/orders/${id}`);
          }else {
            this.$router.push(`/shipments/${id}`);
          }
        },

        clearFilters() {
        	this.filterResultsToggle = false;
        	this.selFilters = {};
        	this.$refs.filter.clearFilters(true)
        	if(this.activeTab == 0) {
        		this.orderPageData({filtering: false})
        	}else{
        		this.shipmentPageData({filtering: false})
        	}
        },

        async shipmentExportButton() {
          this.loader.exporting = true
          try {
            let result = ``;

            if(this.activeTab == 0) {
              result = await this.axios.get(`orders/export`, { params: this.exportData });
            }
            else {
              result = await this.axios.get(`shipments/${this.status[this.activeTab]}/export`, { params: this.exportData });
            }
            if(result.data.success) {
              this.popToast("booked", "Export generated", result.data.message);
            } else {
              this.popToast("requested", "Export generated", "Your Order Export is getting downloaded");
              window.open(result.data.path)
            }
          } catch (err) {
            console.log(err)
          }
          this.loader.exporting = false
        }
      },
      computed: {
        user() {
          return this.$store.getters.user
        },
        checked() {
          return this.$store.getters.global.checkedOrderlist || [];
        },
        windowWidth() {
          return this.$store.state.windowWidth;
        },

        warehousesList() {
          return this.$store.getters.warehouses.map((i) => {
            return {
              name: i.name, 
              id: i.id,
              address: i.address,
              $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
            };
          })
        },
        computedFileds() {
          if(this.activeTab == 0) {
            return this.fields.filter(i => i.key != 'awb')
          }
          else if (this.activeTab != 0) {
            return this.fields.filter(i => i.key != 'SKUs')
          }
          else{
            return this.fields
          }

        },
    modes() {
      return this.$store.getters.mode;
    },
    selectedRows() {
      const items = this.items.filter((item) => item.selected)
      if(items.length>0) {
        this.allSelected = true
      }
      return items
    },
  },
  watch: { 
    '$route.name' (to, from){
      if(to == 'Orders') {
        this.pageTab(0)
      }else{
        this.pageTab(1)
      }
      this.clearFilters()
      // this.perPage = this.$store.state.perPage
      this.$router.push(`?tab=${this.activeTab}&page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
    },
    awb: function(newValue) {
      if(newValue == false) {

      }  
    },
    items: function(newValue) {
      if(newValue.length>0){
        this.isBusy = false
      }
    },
    currentPage: function() {

      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentPage == 1) {
        this.prevDisabled = true;
      }

      if (this.currentPage == this.totalPages) {
        this.nextDisabled = true;
      }
    },
    selectedRows: function() {
      if (this.selectedRows.length == this.items.length) {
        // this.allSelected = false;
      }
    },
    // activeTab: function(tab) {
    //   if(tab == 0) {
    //     this.orderPageData()
    //   }else{
    //     this.shipmentPageData();
    //   }
    // }
  },
};
</script>

<style type="text/css" scoped>
.warning1{
      position: relative;
      left: 0.5rem;
      width: 2rem;
}

.warning2{
      position: relative;
      left: 0.5rem;
      width: 1.5rem;
}
.errorbtn{color: #fff !important;background: #ef5a5a !important;margin-top: 2px !important;}
.quickedit-book {
  background: white
}
.expanded-row {
  padding: 1rem 1.65rem 1rem 1rem !important;
}

/deep/ .multiselect__single {
  white-space: nowrap;
}

/deep/ .b-table-row-selected {
  cursor: pointer !important;
}

.w130px {width: 130px}

.carrier[data-carrier="2"] {
  color: #32A8CB;
}
.carrier[data-carrier="3"] {
  color: #BC0014;
}
.carrier[data-carrier="4"] {
  color: #a064e6;
}
.carrier[data-carrier="5"] {
  color: #EE932C;
}
.carrier[data-carrier="6"] {
  color: #F48D1A;
}
.carrier[data-carrier="7"] {
  color: #0B3082;
}
.carrier[data-carrier="8"] {
  color: #E9201F;
}
.carrier[data-carrier="9"] {
  color: #230965;
}
.carrier[data-carrier="10"] {
  color: #33BB2C;
}
.carrier[data-carrier="11"] {
  color: #15A550;
}
.carrier[data-carrier="12"] {
  color: #127998;
}
.carrier[data-carrier="13"] {
  color: #134A1F;
}
.carrier[data-carrier="14"] {
  color: #36A94F;
}
.carrier[data-carrier="15"] {
  color: #36A94F;
}
.carrier[data-carrier="16"] {
  color: #da433e;
}
.carrier[data-carrier="17"] {
  color: #ec9605;
}
.carrier[data-carrier="18"] {
  color: #adce49;
}
.carrier[data-carrier="19"] {
  color: #2a75c9;
}
.carrier[data-carrier="20"] {
  color: #8e2927;
}
.carrier[data-carrier="21"] {
  color: #36A94F;
}
.carrier[data-carrier="22"] {
  color: #ee3526;
}
.carrier[data-carrier="23"] {
  color: #353896;
}
.carrier[data-carrier="24"] {
  color: #0d4689;
}
.carrier[data-carrier="25"] {
  color: #f16925;
}
.carrier[data-carrier="26"] {
  color: #0ef0b5;
}
.carrier[data-carrier="27"] {
  color: #138707;
}
</style>

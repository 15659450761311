<template>
  <div class="filter-sidebar">
    <div class="divider mx-20 vam"></div>
    <b-button v-b-toggle.sidebarFilters class="text-gray-900" id="side-btn">
      <span >
        Filters
      </span>
      <i class="icon-filter text-gray-600 pl-10"></i>
    </b-button>
    <b-sidebar
      id="sidebarFilters"
      right
      backdrop
      backdrop-variant="dark"
      sidebar-class="filter-side"
      no-header
      shadow
      v-model="sidebarVisible"
    >
      <div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
        <div class="header d-flex align-items-center pb-30">
          <i class="icon-filter text-gray-600 fs24 pr-16"></i>
          <h2 class="text-gray-900">Filters</h2>
          <b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
            <i class="icon-close-variant"></i>
          </b-button>
        </div>

        <b-form-group class="sideFilterclass">
          <b-form-textarea
            class="mb-17"
            title="Search by OrderIDs"
            placeholder="OrderIDs separated by comma"
            v-model="form.orderIDs"
            @input="addFilter({orderIDs: form.orderIDs})"
          ></b-form-textarea>
          
          <multiselect
            id="type"
            v-model="form.type"
            title="Order Type"
            :options="types"
            :searchable="false"
            :show-labels="false"
            placeholder="Select Order Type"
            class="mb-17"
            @input="addFilter({type: form.type})"
          ></multiselect>

          <multiselect
            v-if="sellerId == 165"
            id="skuType"
            v-model="form.skuType"
            title="Sku"
            :options="skuTypes"
            :searchable="false"
            :show-labels="false"
            placeholder="Select SKU Type"
            class="mb-17"
            @input="addFilter({skuType: form.skuType})"
          ></multiselect>

          <multiselect
            id="channel"
            v-model="form.channel"
            :options="channels"
            title="Channel"
            :searchable="false"
            :show-labels="false"
            placeholder="Select Channel"
            label="name"
            class="mb-17"
            @input="addFilter({channel: form.channel ? form.channel.name : ''},'channel')"
          ></multiselect>

          <multiselect
            v-if="showStores && stores.length >=1 "
            id="channel"
            v-model="form.storeName"
            :options="stores"
            title="Stores"
            :searchable="false"
            :show-labels="false"
            placeholder="Select Store"
            label="storeName"
            class="mb-17"
            @input="addFilter({storeName: form.storeName ? form.storeName.storeName : ''})"
          ></multiselect>

          <multiselect
            id="warehouse"
            v-model="form.warehouseID"
            :options="$store.getters.warehouses"
            :searchable="true"
            title="Warehouse"
            :show-labels="false"
            placeholder="Warehouse"
            class="mb-17"
            label="name"
            @input="addFilter({warehouseID: form.warehouseID ? form.warehouseID.name : ''})"
          ></multiselect>

          <date-picker
            prefix-class="xmx"
            v-model="form.date"
            id="dp"
            range
            title="Date"
            value-type="format"
            class="mb-17"
            format="YYYY-MM-DD"
            placeholder="Start Date - End Date"
            :editable="false"
            :disabled-date="(date) => date > new Date()"
            @input="addFilter({date: form.date})"
          ></date-picker>

          <multiselect
            id="validOrder"
            v-model="form.validOrder"
            :options="validOrderList"
            :searchable="false"
            :show-labels="false"
            placeholder="Quick Booking"
            class="mb-17"
            @input="addFilter({validOrder: form.validOrder})"
          ></multiselect>

          <div class="d-flex" style="color: #828b9d;font-size: 0.875rem">
            <b-form-checkbox 
              v-model="form.sortBy"
              @input="addFilter({sortBy: form.sortBy == true ? 'true' : 'false'})"
            ></b-form-checkbox>
            <label>Sort By orderID</label>
          </div>
        </b-form-group>

        <div class="footer mt-auto text-right pb-26 pt-8">
          <b-link
            v-if="this.hasFilters"
            class="secondary fw500 mr-20 wsnw"
            @click="clearFilters()"
          >
            Clear filters
          </b-link>
          <b-button
            :disabled="!this.hasFilters"
            variant="primary"
            @click="applyFilter"
          >
            Apply filter
          </b-button>
        </div>

        <b-form-group>
          <div class="row">
            <div class="w-100 border-top">
              <h6 class="label text-center font-weight-bold mt-20">Master Export</h6>
            </div>
            <div class="col-6">
              <multiselect
                id="type"
                title="Year"
                v-model="yearExport"
                :options="multiYears"
                :searchable="false"
                :show-labels="false"
                placeholder="Year"
                class="mb-17"
              ></multiselect>
            </div>

            <div class="col-6">
              <multiselect
                id="type"
                title="Month"
                v-model="monthExport"
                :options="new Date().getFullYear()==yearExport ? monthNames.slice(0, new Date().getMonth()+1) : monthNames"
                :searchable="false"
                :show-labels="false"
                placeholder="Month"
                class="mb-17"
              ></multiselect>
            </div>
            <div class="w-100 mb-20" data-tooltip="Master Export will be sent to your Registered and Operation Email.">
              <b-button  variant="primary" :disabled="!(monthExport && yearExport)" class="w-sm-100 float-right" @click="masterExport">
                Master Export
              </b-button>
            </div>
          </div>
        </b-form-group>

      </div>
    </b-sidebar>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
  name: "FilterSidebar",
  props: ['noText'],
  data() {
    return {
      monthExport: null,
      yearExport: null,
      sidebarVisible: false,
      selectedFilter: 1,
      typeSelected: null,
      warehouseSelected: null,
      modetypeSelected: null,
      channelSelected: null,
      sellerId:this.$store.getters.user.id,
      types: ["PREPAID", "COD", "REVERSE"],
      skuTypes: ["SINGLE", "MULTI"],
      validOrderList: ["Ready to Book", "Input Required"],
      form: {},
      modetypes:[],
      channels: [],
      selectedFilters: this.$store.getters.filters.shipments || {},
      multiYears: ["2016", "2017", "2018", "2019", "2020", "2021","2022"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
      showStores:false,
      stores:[]
    };
  },
  components: {
    DatePicker,
    Multiselect,
  },
  created() {
  	let form = Object.assign({}, this.$store.getters.filters.shipments)
    if(this.selectedFilters.dateStart) {
      form.date = [this.selectedFilters.dateStart, this.selectedFilters.dateEnd]
    }
  	delete form.dateStart
  	delete form.dateEnd

    if(form.warehouseID) {
      form.warehouseID = this.$store.getters.warehouses.find(i => i.name == form.warehouseID)
    }
    if(form.channel) {
      form.channel = this.$store.getters.channels.find(i => i.name == form.channel)
    }

  	this.form = form
  },
  mounted() {
  	this.getChannels();
  },
  methods: {
  	applyFilter(event) {

  		if(this.selectedFilters.date) {
  			this.selectedFilters.dateStart = this.selectedFilters.date[0]
  			this.selectedFilters.dateEnd = this.selectedFilters.date[1]
  			delete this.selectedFilters.date
  		}

  		this.sidebarVisible = false;
  		this.$store.commit('setFilter', {'shipments': this.selectedFilters})
  		this.$parent.getFilters()
  	},


    addFilter(filter, type) {
      if(Object.values(filter)[0]) {
        this.selectedFilters = {...this.selectedFilters, ...filter};

        // Code When only channel is selected to get stores for connector channels
        if (type && type == 'channel' && this.selectedFilters.hasOwnProperty('channel')) {
          if (['amazon','shopify','opencart','magento','WooCommerce','magento2','flipkart','zoho'].includes(this.selectedFilters.channel)) {
            let channel = this.channels.find(i => i.name == this.selectedFilters.channel)
            this.getStores(channel.value)
          } else {
            this.showStores = false
            delete this.selectedFilters.storeName;
            this.form.storeName = null;
            this.$store.commit('setGlobal',{storenames : null});
            
          }
          
        } else {
        }

      } else {

        let temp = this.selectedFilters
        delete temp[Object.keys(filter)[0]]
        this.selectedFilters = Object.assign({}, temp)
      }

    },

    async getStores(val) {
      try {
        const res = await this.axios.get(`/connectors/${val}`)
        this.form.storeName = null;
        this.$store.commit('setGlobal',{storenames : null})
        if (res.data.success) {
          this.showStores = true
          this.stores = res.data.stores.filter(i => { return (i.storeName != null && i.storeName != "") })
          this.$store.commit('setGlobal',{storenames : this.stores})
        } else {
          this.showStores = false
          this.form.storeName = null;
          this.$store.commit('setGlobal',{storenames : null});
          // this.popToast('failed','Failed',res.data.message)
        }
      } catch(e) {
        this.showStores = false
        this.form.storeName = null;
        this.$store.commit('setGlobal',{storenames : null});
        console.error(e);
      }
      
    },

    async getChannels() {
      const res = await this.axios.get('/statics/channels')
      const resMode = await this.axios.get('/statics/modes')

      this.channels = res.data.filter(i => i.name != '');
      this.modetypes = resMode.data.filter(item => item.name!='').map(item => item.name)

    },
    clearFilters(parent) {
    	let filter = this.form
    	this.form = {}
      this.showStores = false
    	this.selectedFilters = {};
    	this.$store.commit('setFilter', {})
    	if(!parent) {
    		this.$parent.clearFilters();
    	}
    },
    async masterExport() {

      const start = (new Date(parseInt(this.yearExport), this.monthNames.indexOf(this.monthExport), 1, 0, 0, 0))
      const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);

      let result = await this.axios.post('/masterexport', {start, end, year: this.yearExport, month: this.monthNames.indexOf(this.monthExport)});

      if (result.data.response.success == true) {
        this.popToast('booked', "Success!", result.data.response.message);
      }
      else if(result.data.response.success == false) {
        this.popToast('failed', "Failed!", result.data.response.message);
      }
      else {
        this.popToast('failed', "Failed!", 'Something went wrong');
      }

    }
  },
  computed: {
    hasFilters() {
      if (Object.keys(this.selectedFilters).length>0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch : {
    'form.channel':function(newValue) {
      if (newValue == null) {
        this.showStores = false
      } else {
      }
    }
  }
};
</script>

<style lang="scss">
.sideFilterclass {
  margin-bottom:0.5rem !important;
}
.filter-sidebar {
  display: flex;
  align-items: center;
  white-space: nowrap;
  @include media-breakpoint-down(md) {
    .divider {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    // position: fixed;
    // top: rem(86px);
    // right: rem(20px);
    // z-index: 10;
    button {
      i {
        margin: 0 !important;
        padding: 0 !important;
      }
      span {
        display: none;
      }
    }
    .b-sidebar-body {
      height: calc(100% - 86px);
    }
  }

  &.billing-sidebar {
    position: relative !important;
    top: 0;
    right: 0;
    z-index: 8;
  }
}
</style>


<style type="text/css" scoped>
  
[data-tooltip] {
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
  width: 98%;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 5px 10px;
  min-width: 50px;
  max-width: 300px;
  width: 98%;
  border-radius: 6px;
  font-size: 0.8rem;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  width: 98%;
  border-width: 5px 5px 0px 5px; /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top;   /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  width: 98%;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  width: 98%;
  transform: translate(-50%, -5px) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  display: none;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it.
*/






/*
  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}



/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}



/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}










/* Settings that make the pen look nicer */
html {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 1.2em;
  background: linear-gradient(45deg, #243949, #2cacd1, #35eb93);
  background-size: 120% 120%;
  animation: moveFocus 5s ease infinite alternate;
}
@keyframes moveFocus { 
  0%   { background-position: 0% 100% }
  100% { background-position: 100% 0% }
}

body {
  background: none;    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
}

main {
  padding: 0 4%;
  display: flex;
  flex-direction: row;
  margin: auto 0;
}


.example-elements {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding-right: 4%;
}

.example-elements p {
  padding: 6px;
  display: inline-block;
  margin-bottom: 5%;
  color: #fff;
}
.example-elements p:hover {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding-left: 5px;
  padding-right: 5px;
}

.example-elements a {
  margin-left: 6px;
  margin-bottom: calc(5% + 10px);
  color: #76daff;
  text-decoration: none;
}
.example-elements a:hover {
  margin-bottom: calc(5% + 9px);
  border-bottom: 1px solid #76daff;
}

.example-elements button {
  margin-bottom: 20px;
}

.info-wrapper {
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  padding-left: 6%;
  border-left: 3px solid #35ea95;
}

.info-wrapper p {
  color: rgba(255, 255, 255, 0.69);
}
.info-wrapper p {
  max-width: 600px;
  text-align: justify;
}

.info-wrapper .title-question {
  display: block;
  color: #fff;
  font-size: 1.36em;
  font-weight: 500;
  padding-bottom: 24px;
}


@media (max-height: 450px) {
  main {
    margin: 2rem 0;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 0.9em;
  }
}

/* Thumbnail settings */
@media (max-width: 750px) {
  html {
    animation-duration: 0.6s;
    font-size: 1em;
  }
  body {
    display: flex;
    background: none;
    height: 100%;
    margin: 0px;
  }
  main {
    font-size: 1.1em;
    padding: 6%;
  }
  .info-wrapper p:before,
  .info-wrapper p:after {
    display: none;
  }
  .example-elements {
    max-width: 150px;
    font-size: 22px;
  }
  .example-elements a, button {
    display: none;
  }
  .example-elements p:before, 
  .example-elements p:after {
    visibility: visible;
    opacity: 1;
  }
  .example-elements p:before {
    content: "Tooltip";
    font-size: 20px;
    transform: translate(-50%, -5px) scale(1);
  }
  .example-elements p:after {
    transform: translate(-50%, -1px) scaleY(1);
  }
  
  
  [data-tooltip]:after {
    bottom: calc(100% + 3px);
  }
  [data-tooltip]:after {
    border-width: 7px 7px 0px 7px;
  }
}

</style>
